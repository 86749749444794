import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Papa from 'papaparse';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  createJobConfigurationApi,
  fetchProjectApi,
  fetchThemesApi,
  generateThemesApi_v2,
  getJobConfigurationApi,
  getNonEmptyUserContentCount,
  multiTagDataApi,
  multiTagDataSampleSyncApi,
} from '../api/pipelineApi';
import SettingsIcon from '../assets/settings.svg';
import PageHeader from '../components/PageHeader';
import PrimaryButton from '../components/buttons/PrimaryButton';
import TextButtonWithIcon from '../components/buttons/TextButtonWithIcon';
import CodebookAdvancedSettingsModal, {
  OnCloseFunctionTypeParams,
} from '../components/codebookEditor/CodebookAdvancedSettingsModal';
import CenteredHorizontalFlex from '../components/containers/CenteredHorizontalFlex';
import CenteredVerticalFlex from '../components/containers/CenteredVerticalFlex';
import VerticalFlex from '../components/containers/VerticalFlex';
import ErrorModal from '../components/modal/ErrorModal';
import MessageModal from '../components/modal/MessageModal';
import LoadThemesModal from '../components/researchForm/LoadThemesModal';
import LoadingModal from '../components/tagResults/LoadingModal';
import TagResultsModal from '../components/tagResults/TagResultsModal';
import ThemesList from '../components/themes/ThemesList';
import { TagResult, Theme } from '../types';
import { GENERAL_ERROR_MESSAGE } from '../utils/constants';
import { downloadThemesFileUtil } from '../utils/csv-utils';
import logger from '../utils/logger';

interface CodebookEditorPageProps {}

const CodebookEditorPage: React.FC<CodebookEditorPageProps> = ({}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { projectId, jobId, jobVersionId } = useParams();
  const [showLoadThemesModal, setShowLoadThemesModal] = useState(false);
  const [showFeedbacksSampleModal, setShowFeedbacksSampleModal] =
    useState(false);

  const [sampleTagResults, setSampleTagResults] = useState<
    TagResult[] | undefined
  >();

  const [submitError, setSubmitError] = useState({
    title: '',
    subTitle: '',
  });
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showTagAllLoaderModal, setShowTagAllLoaderModal] = useState(false);
  const [showTagAllWarningModal, setShowTagAllWarningModal] = useState(false);
  const [showFetchThemesErrorModal, setShowFetchThemesErrorModal] =
    useState(false);
  const [themes, setThemes] = useState<Theme[]>([]);

  const closeFeedbacksSampleModal = () => {
    setSampleTagResults(undefined);
    setShowFeedbacksSampleModal(false);
  };

  const {
    mutateAsync: createConfigMutateAsync,
    isPending: createConfigIsLoading,
    error: createConfigError,
    reset: createConfigReset,
  } = useMutation<any, Error, object>({
    mutationFn: (data) => createJobConfigurationApi(data, jobVersionId!),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['jobConfiguration', jobVersionId],
      });
    },
  });

  const {
    mutate: tagSampleMutation,
    error: tagSampleError,
    reset: tagSampleReset,
    isPending: tagSampleIsLoading,
  } = useMutation<any, Error, Theme[]>({
    mutationFn: (data) => multiTagDataSampleSyncApi(data, jobVersionId!),
    onSuccess: (data) => {
      () => setShowFeedbacksSampleModal(true);
      setSampleTagResults(data);
    },
    onError: (error) => {
      logger.error('error: ', error);
      closeFeedbacksSampleModal();
    },
  });

  const {
    data: fetchThemesData,
    isFetching: fetchThemesLoading,
    error: fetchThemesError,
  } = useQuery<any, Error>({
    queryKey: ['themes', jobVersionId],
    queryFn: () => fetchThemesApi(jobVersionId!),
    retry: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (fetchThemesError) {
      logger.error('fetchThemesError: ', fetchThemesError);
      setShowFetchThemesErrorModal(true);
    }
  }, [fetchThemesError]);

  useEffect(() => {
    if (fetchThemesData) {
      setThemes(fetchThemesData);
    }
  }, [fetchThemesData]);

  const { data: projectData } = useQuery<any, Error>({
    queryKey: ['project', projectId],
    queryFn: () => fetchProjectApi(projectId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: generateThemesMutate,
    isPending: generateThemesIsLoading,
    error: generateThemesError,
    reset: generateThemesReset,
  } = useMutation<any, Error>({
    mutationFn: () => generateThemesApi_v2(jobVersionId!),
    onSuccess: (data: Theme[]) => {
      setThemes(data);
    },
  });

  const { data: jobConfigurationData } = useQuery<any, Error>({
    queryKey: ['jobConfiguration', jobVersionId],
    queryFn: () => getJobConfigurationApi(jobVersionId!),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: nonEmptyUserContentCountData } = useQuery<any, Error>({
    queryKey: ['nonEmptyUserContentCount', jobId],
    queryFn: () => getNonEmptyUserContentCount(jobId!),
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: tagAllMutation,
    // isPending: tagAllIsLoading,
    error: tagAllError,
    reset: tagAllReset,
  } = useMutation<any, Error, Theme[]>({
    mutationFn: (data) => multiTagDataApi(data, jobVersionId!),
    onSuccess: () => {
      setTimeout(() => {
        setShowTagAllLoaderModal(false);
        navigate('/projects');
      }, 2000);
    },
    onError: (error) => {
      logger.error('error: ', error);
      setShowTagAllLoaderModal(false);
    },
  });

  const removeTheme = (index: number) => {
    setThemes((old) => old.filter((_, i) => i !== index));
  };

  const updateTheme = (index: number, update: Theme) => {
    setThemes((old) => {
      const newThemes = [...old];
      newThemes[index] = update;
      return newThemes;
    });
  };

  const addRow = () => {
    setThemes((old) => [
      ...old,
      { id: Date.now(), name: '', instructions: '' },
    ]);
  };

  const validateForm = () => {
    if (themes.length <= 1) {
      setSubmitError({
        title: 'Codebook Required',
        subTitle:
          'It looks like a codebook is missing. Please load an existing codebook, or allow Blix to generate themes for you. Note that at least two themes are required to proceed',
      });
      return false;
    }

    const allThemesWithValidNames = themes.every(
      (theme) => theme.name?.length > 0
    );
    if (!allThemesWithValidNames) {
      setSubmitError({
        title: 'Empty Theme Name Detected',
        subTitle:
          'It seems like one or more themes are unnamed. Please ensure all themes have valid names before proceeding',
      });
      return false;
    }

    return true;
  };

  const tagSample = () => {
    if (!validateForm()) {
      return;
    }

    setShowFeedbacksSampleModal(true);
    tagSampleMutation(themes);
  };

  const promptTagAllModal = () => {
    if (!validateForm()) {
      return;
    }

    setShowTagAllWarningModal(true);
  };

  const tagAll = () => {
    setShowTagAllWarningModal(false);
    setShowTagAllLoaderModal(true);
    tagAllMutation(themes);
  };

  const selectThemesFile = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      logger.info("couldn't find file");
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: function (results) {
        const localThemes = results.data as Theme[];
        setThemes((old) => [...old, ...localThemes]);
        setShowLoadThemesModal(false);
      },
    });
  }, []);

  const getTagAllWarningMessage = () => {
    const count = nonEmptyUserContentCountData?.count;
    if (count) {
      return `You are about to tag ${count} items. ${count} credits will be consumed from your balance`;
    } else {
      return `You are about to tag all data items`;
    }
  };

  const closeAdvancedSettingsModal = async (
    params: OnCloseFunctionTypeParams
  ) => {
    await createConfigMutateAsync(params);
    setShowSettingsModal(false);
  };

  const downloadThemesFile = () => {
    downloadThemesFileUtil(
      themes,
      `${projectData?.name || 'New_Research'}_codebook`
    );
  };

  return (
    <Container>
      <PageHeader text='Refine the Codebook' />
      <ContentsContainer>
        <TopContainer>
          <ThemesList
            themes={themes}
            onRemoveTheme={removeTheme}
            onUpdateTheme={updateTheme}
            onAddTheme={addRow}
            tagSampleCounter={{}}
          />
          <AdvancedSettingsButton
            text='Advanced Settings'
            icon={<img src={SettingsIcon} />}
            onClick={() => setShowSettingsModal(true)}
          />
        </TopContainer>
        <BottomContainer>
          <ButtonsContainer>
            <PrimaryButton
              label='Regenerate themes'
              withClearBackground
              onClick={generateThemesMutate}
            />
            <PrimaryButton
              label='Download Codebook CSV File'
              withClearBackground
              onClick={downloadThemesFile}
            />
          </ButtonsContainer>
          <ButtonsContainer>
            <PrimaryButton
              label='Back'
              withClearBackground
              onClick={() =>
                navigate(
                  `/projects/create-project/${projectId}/${jobId}/${jobVersionId}`
                )
              }
            />
            <PrimaryButton label='Tag Sample 🪄' onClick={tagSample} />
            <PrimaryButton
              // disabled
              label='Tag All Data 🪄'
              onClick={promptTagAllModal}
            />
          </ButtonsContainer>
        </BottomContainer>
        {showSettingsModal && (
          <CodebookAdvancedSettingsModal
            hidden={!showSettingsModal}
            onClose={closeAdvancedSettingsModal}
            existingTagExampleText={jobConfigurationData?.prompt_example_string}
            existingMaxTags={jobConfigurationData?.max_tags_per_row}
          />
        )}
        <TagResultsModal
          hidden={!showFeedbacksSampleModal}
          onClose={closeFeedbacksSampleModal}
          results={sampleTagResults}
          onTagAll={() => {
            closeFeedbacksSampleModal();
            promptTagAllModal();
          }}
          tagAllDisabled={tagSampleIsLoading}
        />
        <LoadThemesModal
          hidden={!showLoadThemesModal}
          onClose={() => setShowLoadThemesModal(false)}
          onSelectFile={selectThemesFile}
        />
        <LoadingModal
          hidden={!showTagAllLoaderModal}
          title='Processing all data...'
        />
        <LoadingModal
          title='Saving your analysis config...'
          hidden={!createConfigIsLoading}
        />
        <LoadingModal
          hidden={!generateThemesIsLoading}
          title='Regenerating Themes...'
        />
        <LoadingModal hidden={!fetchThemesLoading} title='Loading Themes...' />
        <MessageModal
          show={showTagAllWarningModal}
          onClose={() => setShowTagAllWarningModal(false)}
          approveButtonText='Tag All'
          onApprove={tagAll}
          title='Tag All Data'
          subTitle={getTagAllWarningMessage()}
        />
        <ErrorModal
          show={!!tagAllError}
          onClose={tagAllReset}
          title={'Tag All Error'}
          subTitle={tagAllError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!createConfigError}
          onClose={createConfigReset}
          title={'Project Settings Error'}
          subTitle={createConfigError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={showFetchThemesErrorModal}
          onClose={() => setShowFetchThemesErrorModal(false)}
          title={'Fetching Themes Error'}
          subTitle={fetchThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!generateThemesError}
          onClose={generateThemesReset}
          title={'Regenrate Themes Error'}
          subTitle={generateThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!tagSampleError}
          onClose={tagSampleReset}
          title={'Tag Sample Error'}
          subTitle={tagSampleError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!submitError.title}
          onClose={() => setSubmitError({ title: '', subTitle: '' })}
          title={submitError.title}
          subTitle={submitError.subTitle}
        />
      </ContentsContainer>
    </Container>
  );
};

export default CodebookEditorPage;

const Container = styled(VerticalFlex)`
  flex: 1;
`;

const BottomContainer = styled(VerticalFlex)`
  margin-top: 16px;
  align-items: stretch;
  align-self: stretch;
`;

const TopContainer = styled(VerticalFlex)`
  justify-content: space-between;
  flex: 1;
`;

const ContentsContainer = styled(CenteredVerticalFlex)`
  justify-content: space-between;
  flex: 1;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled(CenteredHorizontalFlex)`
  margin-top: 16px;
  gap: 12px;
  justify-content: center;
`;

const AdvancedSettingsButton = styled(TextButtonWithIcon)`
  align-self: flex-start;
`;
