import { ErrorMessage } from 'formik';
import React from 'react';
import styled from 'styled-components';
import ErrorDivStyle from './ErrorDivStyle';

interface FieldErrorProps {
  name: string;
}

const FieldError: React.FC<FieldErrorProps> = ({ name }) => {
  return <ErrorMessage name={name} component={ErrorDiv} />;
};

export default FieldError;

const ErrorDiv = styled.div`
  ${ErrorDivStyle}
`;
