import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Section from './Section';

interface SectionsProps {
  themes: any[];
  respondentsCount: number;
  layoutDirection: 'LTR' | 'RTL';
  userContentsLayoutDirection: 'LTR' | 'RTL';
}

const Sections: React.FC<PropsWithChildren<SectionsProps>> = ({
  themes,
  respondentsCount,
  layoutDirection,
  userContentsLayoutDirection,
}) => {
  const firstTheme = themes?.[0];
  if (firstTheme?.sub_themes && firstTheme?.sub_themes.length > 0) {
    return (
      <Container>
        {themes.map((theme) => (
          <Sections
            themes={theme.sub_themes}
            respondentsCount={respondentsCount}
            layoutDirection={layoutDirection}
            userContentsLayoutDirection={userContentsLayoutDirection}
          />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <Section
        themes={themes}
        respondentsCount={respondentsCount}
        layoutDirection={layoutDirection}
        userContentsLayoutDirection={userContentsLayoutDirection}
      />
    </Container>
  );
};

export default Sections;

const Container = styled.div`
  margin-bottom: 20px;
`;
