import { useQuery } from '@tanstack/react-query';
import { getJobVersionsForJobApi } from '../api/pipelineApi';
import { JobVersionData } from '../types';

const useIsRestoreVersionEnabled = (
  jobId?: string,
  activeJobVersionId?: string
) => {
  const { data: jobVersionsData } = useQuery<JobVersionData[], Error>({
    queryKey: ['jobConfigurationForProject', jobId, activeJobVersionId],
    queryFn: () => getJobVersionsForJobApi(jobId!),
    refetchOnWindowFocus: false,
    enabled: !!jobId && !!activeJobVersionId,
  });

  return (
    jobVersionsData &&
    jobVersionsData.filter((v) => v.status !== 'Failed').length > 1
  );
};

export default useIsRestoreVersionEnabled;
