import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import TextInputWithLabel from '../TextInputWithLabel';
import Title from '../Title';
import PrimaryButton from '../buttons/PrimaryButton';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import Modal from '../modal/Modal';

export interface OnCloseFunctionTypeParams {
  prompt_example_string?: string;
  max_tags_per_row?: string;
}

type OnCloseFunctionType = (params: OnCloseFunctionTypeParams) => void;
interface CodebookAdvancedSettingsModalProps {
  onClose: OnCloseFunctionType;
  hidden?: boolean;
  existingTagExampleText?: string;
  existingMaxTags?: number;
}

const CodebookAdvancedSettingsModal: React.FC<
  PropsWithChildren<CodebookAdvancedSettingsModalProps>
> = ({ onClose, hidden, existingTagExampleText, existingMaxTags }) => {
  const [tagExampleText] = useState<string | undefined>(existingTagExampleText);
  const [maxTags, setMaxTags] = useState<number | undefined>(existingMaxTags);

  const closeSettingsModal = () => {
    onClose({
      prompt_example_string: tagExampleText,
      max_tags_per_row: maxTags?.toString(),
    });
  };

  return (
    <Modal onClose={closeSettingsModal} hidden={hidden}>
      <Container>
        <HorizonalContainer>
          <VerticalContainer>
            <StyledTitle>Advanced settings</StyledTitle>

            <TextInputWithLabel
              label='Max tags per row'
              value={maxTags ?? ''}
              onChange={(e) =>
                setMaxTags(
                  e.target.value === '' ? undefined : parseInt(e.target.value)
                )
              }
              inputType='number'
            />
          </VerticalContainer>
        </HorizonalContainer>
        <PrimaryButton type='button' label='OK' onClick={closeSettingsModal} />
      </Container>
    </Modal>
  );
};

export default CodebookAdvancedSettingsModal;

const Container = styled(CenteredVerticalFlex)``;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px;
`;

const HorizonalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 36px;
`;
