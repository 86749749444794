import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import Separator from '../Seperator';
import ButtonWithHover from '../buttons/ButtonWithHover';
import Tooltip from '../buttons/Tooltip';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import AddIcon from '../icons/AddIcon';
import DownloadIcon from '../icons/DownloadIcon';
import SettingsIcon from '../icons/SettingsIcon';
import H2 from '../newTextComponents/H2';

interface CodebookControlBarProps {
  onClickDownloadCodebook: VoidFunction;
  onClickAddTheme: VoidFunction;
  onClickSettings: VoidFunction;
}

const CodebookControlBar: React.FC<CodebookControlBarProps> = ({
  onClickDownloadCodebook,
  onClickAddTheme,
  onClickSettings,
}) => {
  return (
    <Container>
      <Separator />
      <Content>
        <H2>Themes</H2>
        <Buttons>
          <Tooltip toolTipText='Add a New Theme'>
            <AddButtonWithActive
              icon={<StyledAddIcon />}
              onClick={onClickAddTheme}
            />
          </Tooltip>
          <Tooltip toolTipText='Download Codebook CSV File'>
            <DownloadButtonWithActive
              icon={<StyledDownloadIcon />}
              onClick={onClickDownloadCodebook}
            />
          </Tooltip>
          <VerticalSeperator />
          <Tooltip shiftRight={4} toolTipText='Settings'>
            <SettingsButtonWithActive
              icon={<StyledSettingsIcon />}
              onClick={onClickSettings}
            />
          </Tooltip>
        </Buttons>
      </Content>
      <Separator />
    </Container>
  );
};

export default CodebookControlBar;

const Container = styled.div``;
const Content = styled(CenteredHorizontalFlex)`
  justify-content: space-between;
  margin: 8px 0;
`;
const Buttons = styled(CenteredHorizontalFlex)``;

const VerticalSeperator = styled.div<{ $active?: boolean }>`
  width: 1px;
  height: 24px;
  border-radius: 100px;
  background-color: ${({ $active }) =>
    $active ? Colors.PURPLE_30 : Colors.NEW_BORDER};
`;

const CommonIconStlye = `
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  ${CommonIconStlye};
`;

const StyledAddIcon = styled(AddIcon)`
  ${CommonIconStlye};
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  ${CommonIconStlye};
`;

const StyledButtonWithHover = styled(ButtonWithHover)`
  > svg {
    border: 1px solid ${Colors.B20};
  }
  padding: 0 12px;
`;

const DownloadButtonWithActive = styled(StyledButtonWithHover)`
  &:active > svg path {
    stroke: ${Colors.WHITE};
  }
  &:active > svg rect {
    stroke: transparent;
  }
`;

const AddButtonWithActive = styled(StyledButtonWithHover)`
  &:active > svg path {
    fill: ${Colors.WHITE};
  }
  &:active > svg rect {
    stroke: transparent;
  }
`;

const SettingsButtonWithActive = styled(ButtonWithHover)`
  padding: 0 0 0 12px;
  &:active > svg g path {
    stroke: ${Colors.P100};
  }
  &:active > svg {
    background-color: transparent;
  }
`;
