import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchProjectApi, getJobsDisplayApi } from '../api/pipelineApi';
import ChevronIcon from '../assets/chevron-right.svg';
import { Colors } from '../theme/Colors';
import logger from '../utils/logger';
import PrimaryButton from './buttons/PrimaryButton';
import CenteredHorizontalFlex from './containers/CenteredHorizontalFlex';
interface BreadcrumbsProps {}

const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const navigate = useNavigate();

  const { projectId, jobId, jobVersionId } = useParams();

  const { data: projectData } = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => fetchProjectApi(projectId!),
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: !!projectId,
  });

  const { data: jobsDisplayData } = useQuery({
    queryKey: ['jobsDisplay', projectId],
    queryFn: () => getJobsDisplayApi(projectId!),
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: !!projectId,
  });

  const job = jobId
    ? jobsDisplayData?.filter(
        (jobDisplay: any) => jobDisplay.jobId?.toString() === jobId
      )?.[0]
    : undefined;

  const clickQuestionId = () => {
    if (job.status === 'Not Analyzed' || job.status === 'Initial') {
      return navigate(`/projects/${projectId}/${jobId}/${jobVersionId}/setup`);
    } else if (job.status === 'Codebook') {
      return navigate(
        `/projects/${projectId}/${jobId}/${jobVersionId}/split-view-codebook-editor`
      );
    } else if (job.status === 'Processed') {
      return navigate(
        `/projects/${projectId}/dashboard/${jobId}/${jobVersionId}`
      );
    } else if (job.status === 'Analyzed') {
      logger.error("Unexpected job status 'Analyzed' in breadcrumbs");
      return navigate(
        `/projects/${projectId}/dashboard/${jobId}/${jobVersionId}`
      );
    } else {
      throw Error('Invalid job status');
    }
  };

  const activeElementIndex = job?.questionId ? 2 : projectData ? 1 : 0;
  const getTextColor = (index: number) =>
    activeElementIndex === index ? Colors.B80 : Colors.P100;

  return (
    <Container>
      {projectId && (
        <Button
          label='Home'
          withClearBackground
          withoutBorder
          textStyle={{
            color: getTextColor(0),
          }}
          onClick={() => navigate('/projects')}
        />
      )}

      {projectData && (
        <>
          <ArrowIcon src={ChevronIcon} />
          <Button
            label={projectData.name}
            withClearBackground
            withoutBorder
            textStyle={{
              color: getTextColor(1),
            }}
            onClick={() => navigate(`/projects/${projectId}`)}
          />
        </>
      )}
      {job?.questionId && (
        <>
          <ArrowIcon src={ChevronIcon} />
          <Button
            label={job.questionId}
            withClearBackground
            withoutBorder
            textStyle={{
              color: getTextColor(2),
            }}
            onClick={clickQuestionId}
          />
        </>
      )}
    </Container>
  );
};

export default Breadcrumbs;

const Container = styled(CenteredHorizontalFlex)``;
const ArrowIcon = styled.img``;

const Button = styled(PrimaryButton)`
  outline: none;
  padding: 8px;
`;
