import { useField } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import FieldError from './FieldError';
import Label from './Label';
import Separator from './Seperator';
import StyledField from './StyledField';

interface Option {
  value: string;
  label: string;
}

export interface SelectorWithLabelProps {
  name: string;
  label: string;
  options: Option[];
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  className?: string;
  disabled?: boolean;
}

const SelectorWithLabel: React.FC<SelectorWithLabelProps> = ({
  name,
  label,
  options,
  onFocus,
  onBlur,
  className,
  disabled,
}) => {
  const [active, setActive] = useState(false);
  const [{ value }, , { setValue }] = useField(name);

  return (
    <Container className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledField
        aria-label={`${name}-input`}
        as='select'
        onFocus={() => {
          setActive(true);
          onFocus?.();
        }}
        onBlur={() => {
          setActive(false);
          onBlur?.();
        }}
        name={name}
        disabled={disabled}
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
      >
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </StyledField>
      <Separator $active={active} />
      <FieldError name={name} />
    </Container>
  );
};

export default SelectorWithLabel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  color: ${Colors.PRIMARY};
`;
