import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getJobVersionsForJobApi } from '../../../api/pipelineApi';
import RestoreVersionIcon from '../../../assets/restore_version.svg';
import { JobVersionData } from '../../../types';
import { GENERAL_ERROR_MESSAGE } from '../../../utils/constants';
import JobVersionMenuItem from '../../dashboard/JobVersionMenuItem';
import MenuItemIcon from '../../menu/MenuItemIcon';
import MenuButton from '../../menu/MenuItemTextButtonWithIcon';
import ErrorModal from '../../modal/ErrorModal';
import LoadingModal from '../../tagResults/LoadingModal';
import useRestoreVersion from './useRestoreVersion';

interface RestoreVersionMenuProps {
  projectId: string;
  jobId: string;
  activeJobVersionId: string;
  setShowMenu: (showMenu: boolean) => void;
}

const RestoreVersionMenu: React.FC<RestoreVersionMenuProps> = ({
  projectId,
  jobId,
  activeJobVersionId,
  setShowMenu,
}) => {
  const { data: jobVersionsData } = useQuery<JobVersionData[], Error>({
    queryKey: ['jobConfigurationForProject', jobId, activeJobVersionId],
    queryFn: () => getJobVersionsForJobApi(jobId!),
    refetchOnWindowFocus: false,
  });

  const {
    restoreVersion,
    restoreVersionIsLoading,
    restoreVersionError,
    restoreVersionReset,
  } = useRestoreVersion(projectId, jobId, activeJobVersionId, setShowMenu);

  return (
    <>
      <MenuButton
        text='Restore Version'
        icon={<MenuItemIcon src={RestoreVersionIcon} />}
        onClick={() => {}}
        withRightArrow
      >
        {jobVersionsData
          ?.filter((v) => v.status !== 'Failed')
          .map((version) => (
            <JobVersionMenuItem
              key={version.id}
              jobVersion={version}
              onClick={() => restoreVersion(version)}
            />
          ))}
      </MenuButton>
      <LoadingModal
        title='Restoring version...'
        hidden={!restoreVersionIsLoading}
      />
      <ErrorModal
        show={!!restoreVersionError}
        onClose={restoreVersionReset}
        title='Unexpected Error'
        subTitle={restoreVersionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
    </>
  );
};

export default RestoreVersionMenu;
