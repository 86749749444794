import styled from 'styled-components';
import VerticalFlex from '../containers/VerticalFlex';

const Menu = styled(VerticalFlex)<{ $visible: boolean }>`
  position: absolute;
  right: 70px;
  top: 46px;
  z-index: 1;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  ${({ $visible }) => ($visible ? 'opacity: 1; visibility: visible;' : null)}
`;

export default Menu;
