import React from 'react';
import styled from 'styled-components';
import ChartIcon from '../../assets/chart.svg';
import NotebookWithPencilIcon from '../../assets/notebook_with_pencil.svg';
import { Colors } from '../../theme/Colors';
import { JobVersionData } from '../../types';
import { dateAndTimeFormatted } from '../../utils/utils';
import TransparentButton from '../buttons/TransparentButton';
import P3 from '../newTextComponents/P3';

interface JobVersionMenuItemProps {
  jobVersion: JobVersionData;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

const JobVersionMenuItem: React.FC<JobVersionMenuItemProps> = ({
  jobVersion,
  onClick,
  className,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled || jobVersion.isActive}
      className={className}
      onClick={onClick}
      type='button'
    >
      {jobVersion.status === 'Codebook' ? (
        <Icon src={NotebookWithPencilIcon} />
      ) : (
        <Icon src={ChartIcon} />
      )}
      <P3>{dateAndTimeFormatted(jobVersion.createdAt)}</P3>
      {jobVersion.isActive && (
        <CurrentVersionLabel>Current version</CurrentVersionLabel>
      )}
    </Button>
  );
};

export default JobVersionMenuItem;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const Button = styled(TransparentButton)<{ disabled?: boolean }>`
  position: relative;
  ${(props) => props.disabled && 'cursor: default;'}
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  &:hover {
    background-color: ${Colors.GRAY_20};
  }
  &:focus {
    outline: none;
  }
`;

const CurrentVersionLabel = styled(P3)`
  color: ${Colors.B40};
  position: absolute;
  right: 12px;
`;
