import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Label from '../Label';
import TransparentButton from './TransparentButton';

interface TextButtonWithIconProps {
  text: string;
  icon: ReactElement;
  onClick: () => void;
  className?: string;
}

const TextButtonWithIcon: React.FC<TextButtonWithIconProps> = ({
  text,
  icon,
  onClick,
  className,
}) => {
  return (
    <Button className={className} onClick={onClick} type='button'>
      <IconContainer>{icon}</IconContainer>
      <Label>{text}</Label>
    </Button>
  );
};

export default TextButtonWithIcon;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
`

const Button = styled(TransparentButton)`
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
