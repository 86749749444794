import { FileObject } from '../types';
import logger from '../utils/logger';

const serverUrl = import.meta.env.VITE_SERVER_URL;
export const fetchFiles = async () => {
  const response = await fetch(`${serverUrl}/list_files`);
  const data: FileObject[] = await response.json();
  data.sort((a, b) => {
    return new Date(a.LastModified) > new Date(b.LastModified) ? -1 : 1;
  });
  return data;
};

const downloadFileResponse = async (response: Response, fileKey: string) => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // Set the downloaded file name
  a.download = fileKey;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadFile = async (fileKey: string) => {
  try {
    const response = await fetch(`${serverUrl}/download_file/${fileKey}`);
    await downloadFileResponse(response, fileKey);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    logger.error('Error downloading file:', error);
  }
};

export const downloadDashboardFile = async (
  jobVersionId: string,
  fileIdentifierKey: string,
  downloadFileName: string
) => {
  try {
    const response = await fetch(
      `${serverUrl}/download_dashboard_file/${jobVersionId}/${fileIdentifierKey}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    await downloadFileResponse(response, downloadFileName);
  } catch (error) {
    logger.error('Error downloading file:', error);
    throw new Error(
      'Error downloading dashboard file, please try again or contact admin for more details'
    );
  }
};

export const downloadProjectActiveVersionsXlsxFile = async (
  projectId: string,
  downloadFileName: string
) => {
  try {
    const response = await fetch(
      `${serverUrl}/download_project_active_versions_xlsx_file/${projectId}`,
      {
        method: 'POST',
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    await downloadFileResponse(response, downloadFileName);
  } catch (error) {
    logger.error('Error downloading file:', error);
    throw new Error(
      'Error downloading file, please try again or contact admin for more details'
    );
  }
};
