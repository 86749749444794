import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { TagResult } from '../../types';
import Title from '../Title';
import PrimaryButton from '../buttons/PrimaryButton';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import CircleOverSquareLoader from '../loaders/CircleOverSquareLoader';
import Modal from '../modal/Modal';
import FeedbackList from './FeedbackList';

interface TagResultsModalProps {
  onClose: VoidFunction;
  hidden: boolean;
  results?: TagResult[];
  onTagAll: VoidFunction;
  tagAllDisabled?: boolean;
}

const TagResultsModal: React.FC<PropsWithChildren<TagResultsModalProps>> = ({
  onClose,
  hidden,
  results,
  onTagAll,
  tagAllDisabled,
}) => {
  return (
    <StyledModal onClose={() => {}} hidden={hidden}>
      <Container>
        <Title>
          {results ? 'Review Tagging Sample' : 'Tagging Sample Data...'}
        </Title>
        <ContentsContainer>
          {results ? (
            <FeedbackList feedbacks={results} />
          ) : (
            <CircleOverSquareLoader />
          )}
        </ContentsContainer>

        <ButtonsContainer>
          <PrimaryButton label='Back' withClearBackground onClick={onClose} />
          <PrimaryButton
            disabled={tagAllDisabled}
            type='button'
            label='Tag All Data 🪄'
            onClick={onTagAll}
          />
        </ButtonsContainer>
      </Container>
    </StyledModal>
  );
};

export default TagResultsModal;

const StyledModal = styled(Modal)`
  min-width: 30%;
  max-height: 80%;
  overscroll-behavior: contain;
`;

const Container = styled(VerticalFlex)`
  width: 85%;
`;

const ContentsContainer = styled(VerticalFlex)`
  overflow: scroll;
  max-height: 60vh;
  margin-bottom: 20px;
  padding: 6px;
`;

const ButtonsContainer = styled(HorizontalFlex)`
  justify-content: flex-end;
  gap: 12px;
`;
