import React, { useEffect, useRef, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { FontFamily } from '../theme/FontFamily';
import TextInputWithIcon from './TextInputWithIcon';
import TooltipBoundingRect from './buttons/TooltipBoundingRect';

interface TextInputWithTooltipProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isDisabled?: boolean;
  contentsContainerStyle?: CSSProperties;
}

const TextInputWithTooltip: React.FC<TextInputWithTooltipProps> = ({
  value,
  onChange,
  isDisabled,
  contentsContainerStyle,
}) => {
  const width = 46;
  const ref = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  const spanRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (spanRef?.current) {
      spanRef.current.textContent = value;
      const textSimulatedWidth = spanRef.current.offsetWidth;
      setHasOverflow(textSimulatedWidth > width);
    }
  }, [spanRef, value]);

  return (
    <Container>
      <TooltipBoundingRect
        disabled={true || !hasOverflow}
        toolTipText={value ?? ''}
        shiftRight={-20}
      >
        <ContentsContainer ref={ref} style={contentsContainerStyle}>
          <HiddenSpan ref={spanRef} />
          <TextInputWithIcon
            inputElementStyle={{ width }}
            disabled={isDisabled}
            value={value}
            onChange={onChange}
            withCheckMark
            containerStyle={{ width: width + 24 }}
          />
        </ContentsContainer>
      </TooltipBoundingRect>
    </Container>
  );
};

export default TextInputWithTooltip;

const Container = styled.div``;

const ContentsContainer = styled.div`
  padding-left: 12px;
  padding-right: 32px;
`;

const textStyle = `
  font-family: ${FontFamily.ROBOTO};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const HiddenSpan = styled.span`
  ${textStyle};
  visibility: hidden;
  position: fixed;
  white-space: pre;
  box-sizing: border-box;
`;
