import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import styled from 'styled-components';
import {
  resetProjectApi,
  uploadDataAndSetupProjectApi,
} from '../../api/pipelineApi';
import FileIcon from '../../assets/file.svg';
import XIcon from '../../assets/x_icon.svg';
import { GENERAL_ERROR_MESSAGE } from '../../utils/constants';
import logger from '../../utils/logger';
import Button from '../buttons/Button';
import PrimaryButton from '../buttons/PrimaryButton';
import VerticalFlex from '../containers/VerticalFlex';
import ErrorModal from '../modal/ErrorModal';
import LoadingModal from '../tagResults/LoadingModal';
import DataFileInputWithDrop from './DataFileInputWithDrop';
import DropArea from './DropArea';

interface UploadDataFileProps {
  projectId: string;
  isFileUploaded: boolean;
  onDataFileProcessComplete: () => void;
  onClickNext: VoidFunction;
}

const UploadDataFile: React.FC<UploadDataFileProps> = ({
  projectId,
  isFileUploaded,
  onDataFileProcessComplete,
  onClickNext,
}) => {
  const queryClient = useQueryClient();
  const [dataFileErrorMessage, setDataFileErrorMessage] = useState('');

  const {
    mutateAsync: uploadDataAndSetupProjectMutateAsync,
    isPending: uploadDataAndSetupProjectIsLoading,
    error: uploadDataAndSetupProjectError,
    reset: uploadDataAndSetupProjectReset,
  } = useMutation<any, Error, FormData>({
    mutationFn: (data) => uploadDataAndSetupProjectApi(data, projectId!),
  });

  const {
    mutateAsync: resetProjectMutateAsync,
    isPending: resetProjectIsLoading,
    error: resetProjectError,
    reset: resetProjectReset,
  } = useMutation<any, Error>({
    mutationFn: () => resetProjectApi(projectId!),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['project', projectId] });
      queryClient.invalidateQueries({ queryKey: ['columnDefinitions'] });
    },
  });

  const onlyUploadFile = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      logger.info("couldn't find file");
      return;
    }

    if (
      file.type !== 'text/csv' &&
      file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.type !== 'application/vnd.ms-excel' &&
      !file.name.toLowerCase().endsWith('.sav')
    ) {
      setDataFileErrorMessage(
        'Unsupported file type, please upload a CSV, XLSX, or SPSS file'
      );
      return;
    }

    const formData = new FormData();
    formData.set('data_file', file, file.name);
    await uploadDataAndSetupProjectMutateAsync(formData);
    onDataFileProcessComplete();
  };

  const removeFile = async () => {
    await resetProjectMutateAsync();
  };

  return (
    <Container>
      {isFileUploaded ? (
        <VerticalFlex>
          <DropArea>
            <FileImageContainer>
              <CancelButton onClick={removeFile}>
                <img src={XIcon} alt='Remove File' />
              </CancelButton>

              <img
                src={FileIcon}
                style={{
                  width: 100,
                }}
                alt='File icon'
              />
            </FileImageContainer>
          </DropArea>
          <NextButton label='Next' onClick={onClickNext} />
        </VerticalFlex>
      ) : (
        <DataFileInputWithDrop onDrop={onlyUploadFile} />
      )}
      <LoadingModal
        title='Removing old data...'
        hidden={!resetProjectIsLoading}
      />
      <ErrorModal
        show={!!resetProjectError}
        onClose={resetProjectReset}
        title={'Remove Old Data File Error'}
        subTitle={resetProjectError?.message || GENERAL_ERROR_MESSAGE}
      />
      <LoadingModal
        title='Uploading data file...'
        hidden={!uploadDataAndSetupProjectIsLoading}
      />
      <ErrorModal
        show={!!uploadDataAndSetupProjectError}
        onClose={uploadDataAndSetupProjectReset}
        title={'Upload Data File Error'}
        subTitle={
          uploadDataAndSetupProjectError?.message || GENERAL_ERROR_MESSAGE
        }
      />
      <ErrorModal
        show={!!dataFileErrorMessage}
        onClose={() => setDataFileErrorMessage('')}
        title={'Data File Format Error'}
        subTitle={dataFileErrorMessage!}
      />
    </Container>
  );
};

export default UploadDataFile;

const Container = styled.div``;

const FileImageContainer = styled.div`
  position: relative;
`;

const NextButton = styled(PrimaryButton)`
  width: 120px;
  align-self: flex-end;
`;

const CancelButton = styled(Button)`
  position: absolute;
  left: -36px;
  top: -30px;
  z-index: 1000;
`;
