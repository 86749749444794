import React from 'react';
import AutoFocusedInputModal from './AutoFocusedInputModal';

interface ProjectRenameInputModalProps {
  show: boolean;
  onClose: () => void;
  value: string;
  onChange: (value: string) => void;
  onRenameProjectClick: () => void;
}

const ProjectRenameInputModal: React.FC<ProjectRenameInputModalProps> = ({
  show,
  onClose,
  value,
  onChange,
  onRenameProjectClick,
}) => {
  return (
    <AutoFocusedInputModal
      maxLength={50}
      show={show}
      onClose={onClose}
      value={value}
      label='Choose New Project Name'
      placeholder='Acme inc.'
      buttonLabel='Rename Project'
      onChangeInputValue={onChange}
      onClickCTA={onRenameProjectClick}
    />
  );
};

export default ProjectRenameInputModal;
