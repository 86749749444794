import styled from 'styled-components';
import HorizontalFlex from './HorizontalFlex';

const PageHeadersContainer = styled(HorizontalFlex)`
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0;
`;

export default PageHeadersContainer;
