import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Keyframes } from 'styled-components/dist/types';
import { Colors } from '../../theme/Colors';

// Define the size options and corresponding scales
const sizes = {
  small: 0.8,
  medium: 1,
  large: 1.2,
};

// Extending the interface to include the size prop
interface CircleOverSquareLoaderProps {
  size?: keyof typeof sizes; // 'small' | 'medium' | 'large'
}

const CircleOverSquareLoader: React.FC<CircleOverSquareLoaderProps> = ({
  size = 'medium',
}) => {
  const scaleFactor = sizes[size];

  const squareKF = keyframes`
    0% {
      transform: rotate(0deg);
      height: ${33 * scaleFactor}px;
      width: ${33 * scaleFactor}px;
    }
    100% {
      transform: rotate(360deg);
      height: ${33 * scaleFactor}px;
      width: ${33 * scaleFactor}px;
    }
  `;

  const circleKF = keyframes`
    0% {
      bottom: ${33 * scaleFactor}px;
    }
    40% {
      bottom: ${65 * scaleFactor}px;
    }
    60% {
      bottom: ${65 * scaleFactor}px;
    }
    100% {
      bottom: ${33 * scaleFactor}px;
    }
  `;

  return (
    <Container $scaleFactor={scaleFactor}>
      <Circle $scaleFactor={scaleFactor} $kf={circleKF} />
      <SquareContainer $scaleFactor={scaleFactor}>
        <Square $scaleFactor={scaleFactor} $kf={squareKF} />
      </SquareContainer>
    </Container>
  );
};

export default CircleOverSquareLoader;

const Container = styled.div<{ $scaleFactor: number }>`
  ${({ $scaleFactor: scaleFactor }) => css`
    height: ${64 * scaleFactor}px;
    width: ${32 * scaleFactor}px;
    transform: scale(1);
  `}
`;

const Circle = styled.div<{ $scaleFactor: number; $kf: Keyframes }>`
  ${({ $scaleFactor: scaleFactor, $kf }) => css`
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    width: ${32 * scaleFactor}px;
    height: ${32 * scaleFactor}px;
    border-radius: ${15 * scaleFactor}px;
    background-color: ${Colors.PRIMARY};
    bottom: ${49 * scaleFactor}px;

    animation: ${$kf} ease-in-out 0.9s infinite;
    animation-delay: 0s;
  `}
`;

const SquareContainer = styled.div<{ $scaleFactor: number }>`
  ${({ $scaleFactor }) => css`
    position: absolute;
    bottom: 0;
    width: ${Math.round(66 * $scaleFactor)}%;
    height: ${Math.round(33 * $scaleFactor)}px;
  `}
`;

const Square = styled.div<{ $scaleFactor: number; $kf: Keyframes }>`
  ${({ $scaleFactor, $kf }) => css`
    background-color: ${Colors.PRIMARY};
    width: ${33 * $scaleFactor}px;
    height: ${33 * $scaleFactor}px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: ${3 * $scaleFactor}px;

    animation: ${$kf} ease-in-out 0.9s infinite;
    -webkit-animation: ${$kf} ease-in-out 0.9s infinite;
    -moz-animation: ${$kf} ease-in-out 0.9s infinite;
    -o-animation: ${$kf} ease-in-out 0.9s infinite;
    animation-delay: 0s;
  `}
`;
