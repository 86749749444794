import styled from 'styled-components';
import { Colors } from '../theme/Colors';

const Separator = styled.div<{ $active?: boolean }>`
  height: 1px;
  width: 100%;
  background-color: ${({ $active }) =>
    $active ? Colors.PURPLE_30 : Colors.BORDER_WITH_OPACITY};
`;

export default Separator;
