import styled from 'styled-components';
import { Colors } from '../../theme/Colors';

const Card = styled.div`
  margin-top: 7px;
  box-shadow: 0px 2px 12px rgba(45, 45, 45, 0.1);
  border-radius: 10px;
  /* max-height: 272px; */
  overflow-y: scroll;
  background-color: ${Colors.WHITE};
`;

export default Card;
