import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import P1 from '../newTextComponents/P1';
import P3 from '../newTextComponents/P3';

interface Feedback {
  id: number;
  text: string;
  themes: string[];
}

interface FeedbackItemProps {
  feedback: Feedback;
  shouldAlignRight?: boolean;
  codebookLanguage: 'Hebrew' | 'English';
}

const FeedbackItem: React.FC<FeedbackItemProps> = ({
  feedback,
  shouldAlignRight,
  codebookLanguage,
}) => {
  return (
    <ItemContainer>
      <FeedbackText $shouldAlignRight={shouldAlignRight}>
        {feedback.text}
      </FeedbackText>
      <TagsContainer $shouldAlignRight={codebookLanguage === 'Hebrew'}>
        {feedback.themes.map((theme, index) => (
          <Tag key={index}>{theme}</Tag>
        ))}
      </TagsContainer>
    </ItemContainer>
  );
};

const ItemContainer = styled(VerticalFlex)`
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
`;

const FeedbackText = styled(P1)<{ $shouldAlignRight?: boolean }>`
  ${({ $shouldAlignRight }) =>
    $shouldAlignRight ? 'text-align: right; direction: rtl;' : ''}
`;

const Tag = styled(P3)`
  padding: 4px 16px;
  background-color: ${Colors.P40};
  border-radius: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TagsContainer = styled(CenteredHorizontalFlex)<{
  $shouldAlignRight: boolean;
}>`
  flex-wrap: wrap;
  gap: 8px;
  direction: ${({ $shouldAlignRight }) => ($shouldAlignRight ? 'rtl' : 'ltr')};
`;

export default FeedbackItem;
