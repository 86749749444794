import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import { FontFamily } from '../theme/FontFamily';

const Label = styled.span`
  text-transform: capitalize;
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${Colors.GRAY_100};
`;
export default Label;
