import React from 'react';

interface InfoIconProps {
  className?: string;
}

const InfoIcon: React.FC<InfoIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 8.93565C11.6866 8.97178 11.4501 9.23713 11.4501 9.5526C11.4501 9.86808 11.6866 10.1334 12 10.1696C12.1647 10.1718 12.3231 10.1069 12.4388 9.98967C12.5545 9.87245 12.6173 9.71314 12.6129 9.54852C12.6085 9.21188 12.3366 8.94005 12 8.93565Z'
        fill='#666666'
      />
      <path
        d='M12 11.2891C11.8368 11.2868 11.6796 11.3507 11.5642 11.4661C11.4488 11.5815 11.3849 11.7387 11.3871 11.9019V14.4515C11.3871 14.79 11.6615 15.0644 12 15.0644C12.3385 15.0644 12.6129 14.79 12.6129 14.4515V11.9183C12.6173 11.7529 12.5547 11.5928 12.4392 11.4743C12.3238 11.3558 12.1654 11.289 12 11.2891Z'
        fill='#666666'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C7.58359 4.0045 4.0045 7.58359 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.9955 7.58359 16.4164 4.0045 12 4ZM12 18.7743C8.25868 18.7743 5.22574 15.7413 5.22574 12C5.22574 8.25868 8.25868 5.22574 12 5.22574C15.7413 5.22574 18.7743 8.25868 18.7743 12C18.7698 15.7395 15.7395 18.7698 12 18.7743Z'
        fill='#666666'
      />
    </svg>
  );
};

export default InfoIcon;
