import React from 'react';

interface SettingsIconProps {
  className?: string;
}

const SettingsIcon: React.FC<SettingsIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame 13'>
        <rect width='32' height='32' fill='transparent' />
        <g id='&#240;&#159;&#166;&#134; icon &#34;settings&#34;'>
          <path
            id='Vector'
            d='M16.0003 19.1993C17.7677 19.1993 19.2003 17.7667 19.2003 15.9993C19.2003 14.232 17.7677 12.7993 16.0003 12.7993C14.2329 12.7993 12.8003 14.232 12.8003 15.9993C12.8003 17.7667 14.2329 19.1993 16.0003 19.1993Z'
            stroke='black'
            strokeWidth='1.23334'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_2'
            d='M24.1307 14.2886L22.9598 11.4613L24.5335 9.60016L22.4002 7.46683L20.5492 9.04864L17.6618 7.86122L16.9978 5.3335H14.9132L14.2392 7.8947L11.4182 9.08385L9.60016 7.46683L7.46683 9.60016L9.01709 11.5083L7.86416 14.3429L5.3335 14.9335V17.0668L7.89468 17.766L9.08363 20.5865L7.46683 22.4002L9.60016 24.5335L11.5107 22.9765L14.2903 24.12L14.9335 26.6668H17.0668L17.7116 24.1209L20.5389 22.95C21.0102 23.2869 22.4002 24.5335 22.4002 24.5335L24.5335 22.4002L22.9505 20.5329L24.1217 17.7047L26.6667 17.0425L26.6668 14.9335L24.1307 14.2886Z'
            stroke='black'
            strokeWidth='1.23334'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
};

export default SettingsIcon;
