import React, { useState } from 'react';
import styled from 'styled-components';
import LoaderIcon from '../../assets/loader_icon.svg';
import NotebookWithPencilIcon from '../../assets/notebook_with_pencil.svg';
import useIsRestoreVersionEnabled from '../../hooks/useIsRestoreVersionEnabled';
import { Colors } from '../../theme/Colors';
import { JobDisplayData, JobStatus } from '../../types';
import RestoreVersionMenu from '../business-flows/restore-version/RestoreVersionMenu';
import PrimaryButton from '../buttons/PrimaryButton';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import MenuItemIcon from '../menu/MenuItemIcon';
import MenuItemTextButtonWithIcon from '../menu/MenuItemTextButtonWithIcon';
import P1 from '../newTextComponents/P1';
import MenuContainer from './MenuContainer';

interface JobActionProps {
  projectId: string;
  job: JobDisplayData;
  onClick: VoidFunction;
  onReAnalyzeQuestion: VoidFunction;
}

const JobAction: React.FC<JobActionProps> = ({
  projectId,
  job,
  onClick,
  onReAnalyzeQuestion,
}) => {
  const [showRestoreVersionMenu, setShowRestoreVersionMenu] = useState(false);
  const [showReanalyzeMenu, setShowReanalyzeMenu] = useState(false);

  const isRestoreVersionEnabled = useIsRestoreVersionEnabled(
    job.jobId,
    job.activeJobVersionId
  );

  return (
    <Container>
      {!job.rowCount ? (
        <>
          <Button disabled label='Analyze' onClick={() => {}} />
          <MenuPlaceholder />
        </>
      ) : [
          JobStatus.NOT_ANALYZED,
          JobStatus.INITIAL,
          JobStatus.CODEBOOK,
        ].includes(job.status) ? (
        <>
          <Button label='Analyze' onClick={onClick} />
          {isRestoreVersionEnabled ? (
            <MenuContainer
              showMenu={showRestoreVersionMenu}
              setShowMenu={setShowRestoreVersionMenu}
            >
              <RestoreVersionMenu
                projectId={projectId}
                jobId={job.jobId!}
                activeJobVersionId={job.activeJobVersionId!}
                setShowMenu={setShowRestoreVersionMenu}
              />
            </MenuContainer>
          ) : (
            <MenuPlaceholder />
          )}
        </>
      ) : job.status === JobStatus.PROCESSED ? (
        <>
          <Button
            label='View Report'
            withClearBackground
            withoutBorder
            onClick={onClick}
          />
          <MenuContainer
            showMenu={showReanalyzeMenu}
            setShowMenu={setShowReanalyzeMenu}
          >
            <MenuButton
              text='Re Analyze Question'
              icon={<MenuItemIcon src={NotebookWithPencilIcon} />}
              onClick={onReAnalyzeQuestion}
              textStyle={{ fontSize: 14 }}
            />
            {isRestoreVersionEnabled && (
              <RestoreVersionMenu
                projectId={projectId}
                jobId={job.jobId!}
                activeJobVersionId={job.activeJobVersionId!}
                setShowMenu={setShowRestoreVersionMenu}
              />
            )}
          </MenuContainer>
        </>
      ) : [JobStatus.PROCESSING, JobStatus.ANALYZED].includes(job.status) ? (
        <>
          <Loader src={LoaderIcon} />
          <MenuPlaceholder />
        </>
      ) : job.status === JobStatus.FAILED ? (
        <>
          <ErrorLabel>Analysis Failed</ErrorLabel>
          <MenuContainer
            showMenu={showReanalyzeMenu}
            setShowMenu={setShowReanalyzeMenu}
          >
            <MenuButton
              text='Re Analyze Question'
              icon={<MenuItemIcon src={NotebookWithPencilIcon} />}
              onClick={onReAnalyzeQuestion}
              textStyle={{ fontSize: 14 }}
            />
            {isRestoreVersionEnabled && (
              <RestoreVersionMenu
                projectId={projectId}
                jobId={job.jobId!}
                activeJobVersionId={job.activeJobVersionId!}
                setShowMenu={setShowRestoreVersionMenu}
              />
            )}
          </MenuContainer>
        </>
      ) : null}
    </Container>
  );
};

export default JobAction;

const Container = styled(CenteredHorizontalFlex)`
  justify-content: center;
`;
const ErrorLabel = styled(P1)`
  flex: 1;
  height: 39px;
  padding: 8px 18px;
  color: ${Colors.ERROR};
`;

const Loader = styled.img`
  flex: 1;
  width: 48px;
  height: 48px;
  animation: rotation 2s linear infinite;
`;

const MenuButton = styled(MenuItemTextButtonWithIcon)``;

const Button = styled(PrimaryButton)`
  flex: 1;
  padding-right: 18px;
`;

const MenuPlaceholder = styled.div`
  width: 32px;
  height: 32px;
  margin: 4px;
  margin-right: 12px;
`;
