import { CSSProperties, PropsWithChildren } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import CloudIcon from '../../assets/cloud.svg';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import Title from '../Title';
import DropArea from './DropArea';

interface DataFileInputWithDropProps {
  onDrop: (acceptedFiles: File[]) => void;
  imageStyle?: CSSProperties;
  textStyle?: CSSProperties;
}

const DataFileInputWithDrop: React.FC<
  PropsWithChildren<DataFileInputWithDropProps>
> = ({ onDrop, imageStyle, textStyle }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropArea $active {...getRootProps()}>
      <Title>Upload your survey data here</Title>
      <input {...getInputProps()} />
      <UploadImage style={imageStyle} src={CloudIcon} alt='cloud' />
      {isDragActive ? (
        <Text style={textStyle}>Drop the file here ...</Text>
      ) : (
        <Text style={textStyle}>Drag here, or click to select files</Text>
      )}
    </DropArea>
  );
};

export default DataFileInputWithDrop;

const Text = styled.p`
  font-family: ${FontFamily.ROBOTO};
  font-size: 18px;
  line-height: 15px;
  color: ${Colors.PRIMARY};
  margin-top: 24px;
`;

const UploadImage = styled.img`
  width: 130px;
`;
