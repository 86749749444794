import React from 'react';

interface FilterIconProps {
  className?: string;
}

const FilterIcon: React.FC<FilterIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.221 8.00049H20.856V8.00799C21.7522 7.99239 22.5662 8.52821 22.9063 9.35757C23.2463 10.1869 23.0427 11.14 22.3935 11.758L18.816 15.3355C18.4178 15.7344 18.1893 16.2719 18.1785 16.8355V20.1355C18.1773 20.8198 17.8553 21.464 17.3085 21.8755L16.3785 22.573C15.7208 23.0734 14.8358 23.1564 14.0966 22.7868C13.3574 22.4172 12.8927 21.6594 12.8985 20.833V16.8355C12.8877 16.2719 12.6592 15.7344 12.261 15.3355L8.68351 11.758C8.02857 11.1409 7.82129 10.1842 8.16208 9.35132C8.50286 8.51847 9.32134 7.98149 10.221 8.00049ZM21.831 9.77799C21.6692 9.38227 21.2835 9.12421 20.856 9.12549H10.221C9.79348 9.12421 9.40786 9.38227 9.24601 9.77799C9.07893 10.1718 9.17132 10.6278 9.47851 10.9255L13.071 14.503C13.6928 15.1196 14.0413 15.9598 14.0385 16.8355V20.833C14.0345 21.1095 14.1405 21.3762 14.3332 21.5746C14.5259 21.7729 14.7895 21.8865 15.066 21.8905C15.292 21.8895 15.5111 21.8129 15.6885 21.673L16.6185 20.9755C16.8829 20.7772 17.0385 20.466 17.0385 20.1355V16.8355C17.0396 15.9576 17.3937 15.1171 18.021 14.503L21.5985 10.9255C21.9057 10.6278 21.9981 10.1718 21.831 9.77799Z'
        fill='#B2B2B2'
      />
    </svg>
  );
};

export default FilterIcon;
