import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import Modal from './Modal';

interface ErrorModalProps {
  show: boolean;
  onClose: VoidFunction;
  title: string;
  subTitle: string | ReactNode;
  topModal?: boolean;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  show,
  onClose,
  title,
  subTitle,
  topModal,
}) => {
  return show ? (
    <Modal topModal={topModal} onClose={onClose} withCloseButton>
      <Container>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </Container>
    </Modal>
  ) : null;
};

export default ErrorModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const Title = styled.div`
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.GRAY_100};
  margin-bottom: 26px;
  max-width: 300px;
  text-align: center;
`;

const SubTitle = styled(Title)`
  font-size: 18px;
  line-height: 20px;
  color: ${Colors.PRIMARY};
  margin-bottom: 24px;
  overflow-x: hidden;
`;
