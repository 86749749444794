import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useQuery } from '@tanstack/react-query';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchAggregateDashboardApi } from '../../api/pipelineApi';
import { Colors } from '../../theme/Colors';
import { GENERAL_ERROR_MESSAGE } from '../../utils/constants';
import { getPastelColor } from '../../utils/utils';
import PageHeader from '../PageHeader';
import BackButtonWithActive from '../buttons/BackButtonWithActive';
import StyledBackIcon from '../buttons/StyledBackIcon';
import VerticalFlex from '../containers/VerticalFlex';
import ErrorModal from '../modal/ErrorModal';
import LoadingModal from '../tagResults/LoadingModal';
import CohortsAggregateGraphFilter from './CohortsAggregateGraphFilter';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    y: {
      ticks: {
        min: 0,
        max: 100,
        callback: function (value: number) {
          return value + '%';
        },
      },
      scaleLabel: {
        display: true,
        labelString: 'Percentage',
      },
      grid: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'right',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return context.dataset.label + ': ' + context.parsed.y + '%';
        },
      },
    },
  },
};

interface TrendsChartProps {}

const TrendsChart: React.FC<TrendsChartProps> = ({}) => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  if (!orgId) {
    throw Error('Organization ID is required');
  }

  const [filterBy] = useState<string | undefined>('OWNER');
  const [filterValue, setFilterValue] = useState<string | undefined>();

  const {
    data: dashboardData,
    isPending: dashboardIsLoading,
    error: dashboardError,
  } = useQuery<any, Error>({
    queryKey: ['dashboard', orgId, filterBy, filterValue],
    queryFn: () => fetchAggregateDashboardApi(orgId, filterBy, filterValue),
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const dataS = dashboardData?.datasets;
  const labels = dashboardData?.labels;

  if (dashboardIsLoading)
    return (
      <LoadingModal title='Loading Dashboard...' hidden={!dashboardIsLoading} />
    );
  if (dashboardError)
    return (
      <ErrorModal
        show={!!dashboardError}
        onClose={() => navigate('/projects')}
        title='Dashboard Loading Error'
        subTitle={dashboardError?.message || GENERAL_ERROR_MESSAGE}
      />
    );

  const datasets = Object.entries(dataS).map(([k, v], i) => ({
    label: k,
    data: v,
    borderColor: getPastelColor(i),
    backgroundColor: getPastelColor(i),
  }));

  const data = {
    labels,
    datasets,
  };

  const clickBackButton = () => {
    navigate(`/projects/`);
  };

  return (
    <Container>
      <PageHeader text='Trends Graph' />
      <Box
        sx={{
          width: '100%',
          maxWidth: '1000px',
          margin: 'auto',
          paddingTop: '20px',
          marginTop: '20px',
          paddingBottom: '20px',
          position: 'relative',
        }}
      >
        <BackButton icon={<StyledBackIcon />} onClick={clickBackButton} />
        <Paper
          elevation={3}
          sx={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '64px',
            paddingTop: '64px',
            position: 'relative',
          }}
        >
          <CohortsAggregateGraphFilter
            filterValues={dashboardData?.filters}
            onSelectFilter={setFilterValue}
          />
          <Line options={options as any} data={data} />
        </Paper>
      </Box>
    </Container>
  );
};

export default TrendsChart;

const BackButton = styled(BackButtonWithActive)`
  position: absolute;
  z-index: 100;
  left: -110px;
  top: 30px;
`;

const Container = styled(VerticalFlex)`
  background-color: ${Colors.WHITE};
`;
