export function debounce(func: any, wait: number) {
  let timeout: any;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const uniqWith = (arr: any[], fn: any) =>
  arr.filter(
    (element, index) => arr.findIndex((step) => fn(element, step)) === index
  );
