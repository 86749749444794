import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  fetchThemesApi,
  getFirstJobVersionForProjectApi,
} from '../../api/pipelineApi';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import { ProjectData, Theme } from '../../types';
import { GENERAL_ERROR_MESSAGE } from '../../utils/constants';
import { downloadThemesFileUtil } from '../../utils/csv-utils';
import logger from '../../utils/logger';
import PrimaryButton from '../buttons/PrimaryButton';
import ButtonsContainer from '../containers/ButtonsContainer';
import LoadingModal from '../tagResults/LoadingModal';
import ErrorModal from './ErrorModal';
import Modal from './Modal';

interface DownloadCodebookModalProps {
  show: boolean;
  project: ProjectData;
  onClose: VoidFunction;
}

const DownloadCodebookModal: React.FC<DownloadCodebookModalProps> = ({
  show,
  project,
  onClose,
}) => {
  const [showGetThemesErrorModal, setShowGetThemesErrorModal] = useState(false);

  // depracated - serves old projects with a single job version per project
  const { data: jobVersionData } = useQuery<any, Error>({
    queryKey: ['jobConfigurationForProject', project.id],
    queryFn: () => getFirstJobVersionForProjectApi(project.id.toString()),
    refetchOnWindowFocus: false,
  });

  const {
    // data: fetchThemesData,
    error: fetchThemesError,
    refetch: fetchThemesRefetch,
    isFetching: fetchThemesLoading,
  } = useQuery<Theme[], Error>({
    queryKey: ['themes', jobVersionData?.id],
    queryFn: () => fetchThemesApi(jobVersionData?.id),
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (fetchThemesError) {
      logger.error('fetchThemesError: ', fetchThemesError);
      setShowGetThemesErrorModal(true);
    }
  }, [fetchThemesError]);

  const onDownloadCodebookClick = async () => {
    const fetchThemesData = await fetchThemesRefetch();
    const fileName = `${
      jobVersionData?.dashboard_title || 'Survey Analysis'
    }_codebook`;
    if (fetchThemesData?.data) {
      downloadThemesFileUtil(fetchThemesData.data, `${fileName}`);
      onClose();
    } else {
      alert('no themes were found, please contact admin');
    }
  };

  if (!show) return null;

  return (
    <Modal onClose={onClose} withCloseButton>
      <Container>
        <Title>New project stucture</Title>
        <SubTitle>
          We have updated Blix with a new flow and this project page is no
          longer available. To continue, please download the project codebook
          below, and create a new project with the same data file. If you need
          any further assistance, please contact our support at{' '}
          <a href='mailto:info@blix.ai'>info@blix.ai</a>.
        </SubTitle>
        <ButtonsContainer>
          <PrimaryButton
            type='button'
            label='download codebook'
            onClick={onDownloadCodebookClick}
          />
        </ButtonsContainer>
        <LoadingModal title='Getting Codebook' hidden={!fetchThemesLoading} />
        <ErrorModal
          show={!!showGetThemesErrorModal}
          onClose={() => setShowGetThemesErrorModal(false)}
          title='Unexpected Error'
          subTitle={GENERAL_ERROR_MESSAGE}
        />
      </Container>
    </Modal>
  );
};

export default DownloadCodebookModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const Title = styled.div`
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.GRAY_100};
  margin-bottom: 16px;
  max-width: 300px;
  text-align: center;
`;

const SubTitle = styled(Title)`
  font-size: 18px;
  line-height: 20px;
  color: ${Colors.PRIMARY};
  margin-bottom: 24px;
`;
