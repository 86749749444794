import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../types';
import logger from '../../../utils/logger';
import { areThemeIdsEqual } from '../../../utils/theme-utils';
import { escapeRegExp } from '../../../utils/utils';
import Card from './Card';
import EmptyResults from './EmptyResults';
import ThemeRowWithCheckbox from './ThemeRowWithCheckbox';

interface FilterSeachResultsProps {
  activeFilters: Theme[];
  themes: Theme[];
  inputValue: string;
  onToggleTheme: (theme: Theme) => void;
  highlightedRowIndex?: number;
}

const testMatch = (value: string, testAgainst: string) => {
  try {
    return new RegExp(escapeRegExp(value), 'i').test(testAgainst);
  } catch (error) {
    logger.error(`[testMatch] new RegExp failed`, error, {
      value,
      testAgainst,
    });
    return false;
  }
};
const FilterSeachResults: React.FC<FilterSeachResultsProps> = ({
  activeFilters,
  themes,
  inputValue,
  onToggleTheme,
  highlightedRowIndex,
}) => {
  return (
    <Container>
      {themes.length > 0 ? (
        <Card>
          {themes.map((theme, index) => (
            <ThemeRowWithCheckbox
              label={theme.name}
              key={theme.name + index}
              selected={testMatch(theme.name, inputValue)}
              onClick={() => onToggleTheme(theme)}
              highlightText={inputValue}
              highlightRow={highlightedRowIndex === index}
              checked={activeFilters.some((f) => areThemeIdsEqual(f, theme))}
            />
          ))}
        </Card>
      ) : (
        <EmptyResults />
      )}
    </Container>
  );
};

export default FilterSeachResults;

const Container = styled.div``;
