import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import ButtonWithHover from '../buttons/ButtonWithHover';
import Tooltip from '../buttons/Tooltip';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import InfoIcon from '../icons/InfoIcon';

interface OtherThemeItemProps {
  tagSampleCounter?: number;
  totalTagsCount?: number;
  scrollOffset: number;
}

const OtherThemeItem = React.forwardRef<
  HTMLInputElement | null,
  OtherThemeItemProps
>(({ tagSampleCounter, totalTagsCount, scrollOffset }, ref) => {
  const [width, setWidth] = useState(0);
  const spanRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (spanRef?.current) {
      spanRef.current.textContent = 'Other';
      const width = spanRef.current.offsetWidth;
      setWidth(width);
    }
  }, [spanRef]);

  return (
    <ItemContainer>
      <HiddenSpan ref={spanRef} />
      <HeaderContainer>
        <TitleContainer>
          <NameInput
            disabled
            style={{
              width,
            }}
            type='text'
            autoFocus
            ref={ref}
            value={'Other'}
            onChange={() => {}}
          />
          {tagSampleCounter && totalTagsCount ? (
            <ThemeRatioContainer>
              <ThemeRatioLabel>
                {Math.round((100 * tagSampleCounter) / totalTagsCount)}%
              </ThemeRatioLabel>
            </ThemeRatioContainer>
          ) : (
            ''
          )}
        </TitleContainer>
        <Tooltip
          scrollOffset={scrollOffset}
          shiftLeft
          shiftRight={-14}
          toolTipText="Auto-created for untagged results. Large 'Other' (%) indicates missing crucial data categories. Ensure full tag coverage; aim for 5%-10% for accuracy."
        >
          <TrashButtonWithActive icon={<InfoIcon />} onClick={() => {}} />
        </Tooltip>
      </HeaderContainer>
    </ItemContainer>
  );
});

const HeaderContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  margin-bottom: 8px;
`;

const HiddenSpan = styled.span`
  visibility: hidden;
  position: fixed;
  white-space: pre;
`;

const ThemeRatioContainer = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${Colors.P30};
`;

const ThemeRatioLabel = styled.div`
  color: ${Colors.B100};
  font-family: ${FontFamily.ROBOTO};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const TitleContainer = styled(HorizontalFlex)``;

const ItemContainer = styled(CenteredVerticalFlex)`
  height: 91px;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${Colors.B10};
  padding: 16px 16px 20px 16px;
  align-items: stretch;
`;

const NameInput = styled.input`
  font-family: ${FontFamily.ROBOTO};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${Colors.B100};
  margin-right: 12px;
  border: none;
  text-align: left;
  outline: none;
  background-color: transparent;
  min-width: 50px;
  max-width: 320px;
  box-sizing: border-box;
`;

const TrashButtonWithActive = styled(ButtonWithHover)`
  padding: 0;
  > svg rect {
    stroke: transparent;
  }
  &:hover > svg rect {
    stroke: ${Colors.B20};
  }
  &:active > svg path {
    stroke: ${Colors.P100};
  }
  &:active > svg rect {
    stroke: transparent;
  }
  &:active > svg {
    background-color: transparent;
  }
`;

export default OtherThemeItem;
