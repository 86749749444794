import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getCurrentUserApi } from '../api/pipelineApi';
import { getUser, removeUser, saveUser } from '../utils/userLocalStorage';

export interface User {
  email: string;
  id: number;
  organization_id: number;
}

function useUserProd(): User | null {
  const { data: user, error } = useQuery<User | null>({
    queryKey: ['current_user'],
    queryFn: async (): Promise<User | null> => getCurrentUserApi(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: getUser,
  });

  useEffect(() => {
    if (!user || error) removeUser();
    else saveUser(user);
  }, [user, error]);

  return user ?? null;
}

// function useUserDev(): User | null {
//   return { id: 1, email: '', organization_id: 1 };
// }

export const useUser = useUserProd;
