import React from 'react';
import AutoFocusedInputModal from './AutoFocusedInputModal';

interface ProjectNameInputModalProps {
  show: boolean;
  onClose: () => void;
  value: string;
  onChange: (value: string) => void;
  onCreateProject: () => void;
}

const ProjectNameInputModal: React.FC<ProjectNameInputModalProps> = ({
  show,
  onClose,
  value,
  onChange,
  onCreateProject,
}) => {
  return (
    <AutoFocusedInputModal
      maxLength={50}
      show={show}
      onClose={onClose}
      value={value}
      label='Project Name'
      placeholder='Acme inc.'
      buttonLabel='Create Project'
      onChangeInputValue={onChange}
      onClickCTA={onCreateProject}
    />
  );
};

export default ProjectNameInputModal;
