import { Colors } from '../theme/Colors';
import { FontFamily } from '../theme/FontFamily';

const fieldStyle = `
  margin: 6px 0 6px 0;
  border: none;
  outline: none;
  color: ${Colors.GRAY_100};
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  background-color: transparent;

  &::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.GRAY_100_02_OPACITY};
  }

  &::-ms-input-placeholder { /* Edge 12 -18 */
    font-size: 16px;
    line-height: 24px;
    color:${Colors.GRAY_100_02_OPACITY};
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export default fieldStyle;
