import React from 'react';

interface ReloadIconProps {
  className?: string;
}

const ReloadIcon: React.FC<ReloadIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.9106 17.2C23.3316 21.049 20.0104 24 16 24C11.5817 24 8 20.4182 8 16C8 11.5817 11.5817 8 16 8C19.2805 8 22.0998 9.97455 23.3343 12.8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 12.8003H23.52C23.7851 12.8003 24 12.5854 24 12.3203V8.80029'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReloadIcon;
