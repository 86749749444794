import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface ThemeDetailsProps {
  themeName: string;
  percentage: number;
  section_summary: string;
  citations: string[];
  layoutDirection: 'LTR' | 'RTL';
  userContentsLayoutDirection: 'LTR' | 'RTL';
}

const ThemeDetails: React.FC<PropsWithChildren<ThemeDetailsProps>> = ({
  themeName,
  percentage,
  section_summary,
  citations,
  layoutDirection,
  userContentsLayoutDirection,
}) => {
  const textAlign = layoutDirection === 'LTR' ? 'left' : 'right';
  const userContentTextAlign =
    userContentsLayoutDirection === 'LTR' ? 'left' : 'right';
  return (
    <Container style={{ marginBottom: 12 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id='{sectionName}-{themeName}'
        >
          <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>
            {themeName} ({percentage}%)
          </Typography>
          <Typography
            sx={{
              color: 'text.secondary',
              textAlign: textAlign,
            }}
          >
            {section_summary}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dir={userContentsLayoutDirection}>
            <ul style={{ textAlign: userContentTextAlign }}>
              {citations?.map((citation, index) => {
                return (
                  <ListItem key={index}>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        textAlign: userContentsLayoutDirection,
                      }}
                    >
                      "{citation}"&nbsp;
                    </Typography>
                  </ListItem>
                );
              })}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default ThemeDetails;

const Container = styled.div`
  margin-bottom: 5px;
`;

const ListItem = styled.li`
  padding-bottom: 16px;
  font-style: italic;
`;
