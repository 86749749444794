import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import FieldError from './FieldError';
import Label from './Label';
import Separator from './Seperator';
import Field from './StyledField';

export interface TextInputWithLabelProps {
  label: string;
  name: string;
  placeholder?: string;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  className?: string;
  disabled?: boolean;
  inputType?: string;
  maxLength?: number;
}

const TextAreaWithLabel: React.FC<TextInputWithLabelProps> = ({
  name,
  label,
  placeholder,
  onFocus,
  onBlur,
  className,
  disabled,
  inputType,
  maxLength,
}) => {
  const [active, setActive] = useState(false);

  return (
    <Container className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledField
        aria-label={`${name}-input`}
        autoComplete='off'
        onFocus={() => {
          setActive(true);
          onFocus?.();
        }}
        onBlur={() => {
          setActive(false);
          onBlur?.();
        }}
        as='textarea'
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        type={inputType}
        maxLength={maxLength}
      />
      <Separator $active={active} />
      <FieldError name={name} />
    </Container>
  );
};

export default TextAreaWithLabel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  color: ${Colors.PRIMARY};
`;

const StyledField = styled(Field)`
  height: 26px;
`;
