import React from 'react';
import { Colors } from '../../theme/Colors';

interface BackIconProps {
  className?: string;
}

const BackIcon: React.FC<BackIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3 16.5L18 22.1C18.4 22.5 19 22.5 19.4 22.1C19.8 21.7 19.8 21.1 19.4 20.7L14.5 15.7L19.4 10.7C19.8 10.3 19.8 9.7 19.4 9.3C19.2 9.1 19 9 18.7 9C18.4 9 18.2 9.1 18 9.3L12.3 14.9C11.9 15.4 11.9 16 12.3 16.5C12.3 16.4 12.3 16.4 12.3 16.5Z'
        fill={Colors.P100}
      />
    </svg>
  );
};

export default BackIcon;
