import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { number } from 'yup';
import {
  createJobConfigurationApi,
  fetchProjectApi,
  generateThemesApi_v2,
  getJobConfigurationApi,
  getJobDisplayApi,
  saveThemesApi,
} from '../api/pipelineApi';
import SettingsIcon from '../assets/settings.svg';
import { useFormContext } from '../state/FormContext';
import { Theme } from '../types';
import { GENERAL_ERROR_MESSAGE } from '../utils/constants';
import { parseXLSXOrCSVThemesFileFactory } from '../utils/file-utils';
import PageHeader from './PageHeader';
import TextFieldWithLabel from './TextFieldWithLabel';
import BackButtonWithActive from './buttons/BackButtonWithActive';
import PrimaryButton from './buttons/PrimaryButton';
import StyledBackIcon from './buttons/StyledBackIcon';
import TextButtonWithIcon from './buttons/TextButtonWithIcon';
import CenteredHorizontalFlex from './containers/CenteredHorizontalFlex';
import HorizontalFlex from './containers/HorizontalFlex';
import PageContainer from './containers/PageContainer';
import PageHeadersContainer from './containers/PageHeadersContainer';
import VerticalFlex from './containers/VerticalFlex';
import ErrorModal from './modal/ErrorModal';
import H1 from './newTextComponents/H1';
import LoadThemesModal from './researchForm/LoadThemesModal';
import ResearchSettingsModal from './researchForm/ResearchSettingsModal';
import LoadingModal from './tagResults/LoadingModal';

interface JobSetupPageProps {}

interface ProjectFormValues {
  themes?: object[];
  company_name: string;
  question: string;
  codebook_language: string;
  company_metadata?: string;
  prompt_example_string?: string;
  with_sentiment?: boolean;
  with_other_bucket?: boolean;
  number_of_themes?: number;
  max_tags_per_row?: number;
  interaction_type?: string;
  interaction_type_plural?: string;
  interaction_source?: string;
  sample_size?: number;
  mode?: number;
  minimum_filtered_content_char_length?: number;
  minimium_required_count_of_related_interactions?: number;
}

const validationSchema = Yup.object({
  question: Yup.string().required('Question is required'),
  number_of_themes: number()
    .required('Number of themes is required')
    .min(1, 'Minimum value is 1')
    .max(200, 'Maximum value is 200'),
  max_tags_per_row: number().optional().min(0, 'Minimum value is '),
  minimum_filtered_content_char_length: number()
    .optional()
    .min(0, 'Minimum value is 0'),
  sample_size: number().optional().min(0, 'Minimum value is 0'),
  minimium_required_count_of_related_interactions: number()
    .optional()
    .min(0, 'Minimum value is 0'),
  codebook_language: Yup.string()
    .required('Codebook language is required')
    .test(
      'language',
      'Must be English or Hebrew',
      (val) => val === 'English' || val === 'Hebrew'
    ),
});

const minimalInitialValues: ProjectFormValues = {
  company_name: '',
  question: '',
  codebook_language: 'English',
  // question: 'what did you like?',
  with_sentiment: false,
  with_other_bucket: true,
  number_of_themes: 10,
  max_tags_per_row: 3,
  minimium_required_count_of_related_interactions: 0,
  mode: import.meta.env.VITE_ENVIRONMENT === 'staging' ? 4 : 2,
  // mode: 4,
  // minimum_filtered_content_char_length: 1
};

const AnalysisSetupPage: React.FC<
  PropsWithChildren<JobSetupPageProps>
> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { projectId, jobId, jobVersionId } = useParams();
  const [showLoadThemesModal, setShowLoadThemesModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [invalidThemesFileError, setInvalidThemesFileError] = useState<
    string | ReactNode
  >();

  const { isDemo } = useFormContext();

  const { isPending: projectLoading } = useQuery<any, Error>({
    queryKey: ['project', projectId],
    queryFn: () => fetchProjectApi(projectId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const { data: jobDisplayData, isPending: jobDisplayIsPending } = useQuery<
    any,
    Error
  >({
    queryKey: ['jobDisplay', projectId, jobId],
    queryFn: () => getJobDisplayApi(projectId!, jobId!),
    retry: 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!jobDisplayData) return;

    if (
      ['Analyzed', 'Processed', 'Processing', 'Failed'].includes(
        jobDisplayData.status
      )
    ) {
      navigate(`/projects/${projectId}`);
    }
  }, [jobDisplayData, navigate, projectId]);

  const {
    data: jobConfigurationData,
    isPending: jobConfigurationLoading,
    // isError: jobConfigurationIsError,
  } = useQuery<any, Error>({
    queryKey: ['jobConfiguration', jobVersionId],
    queryFn: () => getJobConfigurationApi(jobVersionId!),
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const initialValues = {
    ...minimalInitialValues,
    ...jobConfigurationData,
  };

  if (jobDisplayData?.columnHeader && !initialValues.question) {
    if (jobDisplayData?.columnHeader.split(' ').length > 2) {
      initialValues.question = jobDisplayData?.columnHeader;
    }
  }

  if (isDemo) {
    initialValues.mode = '1';
  }

  const {
    mutateAsync: createConfigMutateAsync,
    isPending: createConfigIsLoading,
    error: createConfigError,
    reset: createConfigReset,
  } = useMutation<any, Error, object>({
    mutationFn: (data) => createJobConfigurationApi(data, jobVersionId!),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['jobConfiguration'] });
    },
  });

  const {
    mutate: generateThemesMutate,
    isPending: generateThemesIsLoading,
    error: generateThemesError,
    reset: generateThemesReset,
  } = useMutation<any, Error>({
    mutationFn: () => generateThemesApi_v2(jobVersionId!),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ['themes', jobVersionId],
      });
      queryClient.resetQueries({
        queryKey: ['tag_page', jobVersionId],
      });
      navigate(
        `../${projectId}/${jobId}/${jobVersionId}/split-view-codebook-editor`
      );
    },
  });

  const {
    mutateAsync: saveThemesMutateAsync,
    isPending: saveThemesIsLoading,
    error: saveThemesError,
    reset: saveThemesReset,
  } = useMutation<any, Error, Theme[]>({
    mutationFn: (themes) => saveThemesApi(themes, jobVersionId!),
  });

  const selectThemesFileOnComplete = async (results: Theme[]) => {
    await saveThemesMutateAsync(results);
    queryClient.resetQueries({
      queryKey: ['themes', jobVersionId],
    });
    queryClient.resetQueries({
      queryKey: ['tag_page', jobVersionId],
    });
    setShowLoadThemesModal(false);
    navigate(
      `../${projectId}/${jobId}/${jobVersionId}/split-view-codebook-editor`
    );
  };

  const selectThemesFileOnError = () =>
    setInvalidThemesFileError(
      <div>
        The file you've uploaded does not match the required format. Please
        refer to our format guidelines, or contact our{' '}
        <a href='mailto:support@blix.ai'>support team</a> for a valid template
      </div>
    );

  const selectThemesFile = parseXLSXOrCSVThemesFileFactory(
    selectThemesFileOnComplete,
    selectThemesFileOnError
  );

  const goBack = () => {
    navigate(`/projects/${projectId}`);
  };

  const handleSubmit = async (values: ProjectFormValues) => {
    await createConfigMutateAsync(values);
    generateThemesMutate();
  };

  const loadThemes = async (values: ProjectFormValues) => {
    await createConfigMutateAsync(values);
    setShowLoadThemesModal(true);
  };

  const isLoading =
    projectLoading || jobConfigurationLoading || jobDisplayIsPending;
  if (isLoading) {
    return (
      <LoadingModal
        title='Loading Project Configuration...'
        hidden={!isLoading}
      />
    );
  }

  return (
    <PageContainer>
      <PageHeader />
      <PageHeadersContainer>
        <BackButtonWithActive icon={<StyledBackIcon />} onClick={goBack} />
        <H1>Fill in the question asked in the survey</H1>
      </PageHeadersContainer>
      <ContentsContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, validateForm, setFieldTouched }) => {
            const validateResearchForm = async () => {
              setFieldTouched('question');
              return validateForm();
            };
            return (
              <Form>
                <FormContainer>
                  <Container>
                    <ContentsContainer>
                      <TextFieldWithLabel
                        label=''
                        name='question'
                        placeholder='e.g. Why are you highly likely to recommend Acme Inc?'
                      />
                      <ButtonsContainer>
                        <AdvancedSettingsButton
                          text='Advanced Settings'
                          icon={<img src={SettingsIcon} />}
                          onClick={async () => {
                            const errors = await validateResearchForm();
                            if (Object.keys(errors).length === 0) {
                              setShowSettingsModal(true);
                            }
                          }}
                        />
                      </ButtonsContainer>
                      <ResearchSettingsModal
                        hidden={!showSettingsModal}
                        onClose={async () => {
                          const errors = await validateResearchForm();
                          if (Object.keys(errors).length === 0) {
                            setShowSettingsModal(false);
                          }
                        }}
                      />
                    </ContentsContainer>
                  </Container>
                  <BigButtonsContainer>
                    <PrimaryButton
                      label='Load Themes'
                      withClearBackground
                      onClick={async () => {
                        setFieldTouched('question');
                        const errors = await validateForm();
                        if (Object.keys(errors).length === 0) {
                          loadThemes(values);
                        }
                      }}
                    />
                    <PrimaryButton
                      type='submit'
                      label='Generate Themes 🪄'
                      disabled={generateThemesIsLoading}
                    />
                  </BigButtonsContainer>
                  <LoadingModal
                    title='Extracting themes'
                    hidden={!generateThemesIsLoading}
                  />
                  <LoadingModal
                    hidden={!saveThemesIsLoading}
                    title='Saving Themes...'
                  />
                  <LoadingModal
                    title='Saving your analysis config...'
                    hidden={!createConfigIsLoading}
                  />
                  <LoadThemesModal
                    hidden={!showLoadThemesModal}
                    onClose={() => setShowLoadThemesModal(false)}
                    onSelectFile={selectThemesFile}
                  />
                </FormContainer>
              </Form>
            );
          }}
        </Formik>
        <ErrorModal
          show={!!generateThemesError}
          onClose={generateThemesReset}
          title={'Generate Themes Error'}
          subTitle={generateThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!saveThemesError}
          onClose={saveThemesReset}
          title={'Save Themes Error'}
          subTitle={saveThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!createConfigError}
          onClose={createConfigReset}
          title={'Project Settings Error'}
          subTitle={createConfigError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          topModal
          show={!!invalidThemesFileError}
          onClose={() => setInvalidThemesFileError(undefined)}
          title={'Unsupported File Format'}
          subTitle={invalidThemesFileError!}
        />
      </ContentsContainer>
    </PageContainer>
  );
};

export default AnalysisSetupPage;

const ContentsContainer = styled(VerticalFlex)`
  align-items: stretch;
  justify-content: center;
  margin-top: 48px;
`;

const FormContainer = styled(VerticalFlex)`
  flex-direction: column;
  height: 352px;
`;

const BigButtonsContainer = styled(HorizontalFlex)`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 16px;
`;

const Container = styled(VerticalFlex)`
  margin-top: 84px;
  width: 500px;
  display: flex;
`;

const ButtonsContainer = styled(CenteredHorizontalFlex)`
  margin-top: 16px;
  gap: 12px;
  justify-content: space-between;
`;

const AdvancedSettingsButton = styled(TextButtonWithIcon)`
  padding: 0;
  gap: 2px;
  > div {
    height: 32px;
    width: 32px;
  }
`;
