import React, { PropsWithChildren, useRef } from 'react';
import styled from 'styled-components';
import ThreeDotsMenuIcon from '../../assets/three_dots_menu.svg';
import useOutsideClickHandler from '../../hooks/useOutsideClickHandler';
import { Colors } from '../../theme/Colors';
import VerticalFlex from '../containers/VerticalFlex';

interface MenuContainerProps {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuContainer: React.FC<PropsWithChildren<MenuContainerProps>> = ({
  showMenu,
  setShowMenu,
  children,
}) => {
  const menuIconRef = useRef<HTMLImageElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([menuIconRef, menuRef], () => {
    setShowMenu(false);
  });

  return (
    <>
      <MenuIcon
        ref={menuIconRef}
        className='cm-menu-icon-visible'
        src={ThreeDotsMenuIcon}
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu((x) => !x);
        }}
        $visible={showMenu}
      />
      {showMenu && (
        <Menu ref={menuRef} $visible={showMenu}>
          <MenuContents>{children}</MenuContents>
        </Menu>
      )}
    </>
  );
};

export default MenuContainer;

const MenuContents = styled(VerticalFlex)`
  position: absolute;
  right: 52px;
  top: 26px;
  z-index: 1;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
`;

const MenuIcon = styled.img<{ $visible: boolean }>`
  height: 32px;
  width: 32px;
  opacity: 0;
  z-index: 2;
  margin: 4px;
  margin-right: 12px;
  border-radius: 6px;
  visibility: hidden;
  transition: opacity 0.7s, visibility 0.7s;

  ${({ $visible }) => ($visible ? 'opacity: 1; visibility: visible;' : null)}

  &:hover {
    ${({ $visible }) =>
      !$visible &&
      `
    background-color: ${Colors.GRAY_20};
    border-radius: 50%;
  `}
  }

  ${({ $visible }) =>
    $visible &&
    `
    background-color: ${Colors.GRAY_40};
    border-radius: 50%;
  `}
`;

const Menu = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  ${({ $visible }) => ($visible ? 'opacity: 1; visibility: visible;' : null)}
`;
