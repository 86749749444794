import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import { Theme } from '../../types';
import ButtonWithHover from '../buttons/ButtonWithHover';
import Tooltip from '../buttons/Tooltip';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import TrashIcon from '../icons/TrashIcon';

interface ThemeItemProps {
  theme: Theme;
  onRemove: (theme: Theme) => void;
  setValue: (theme: Theme) => void;
  tagSampleCounter?: number;
  totalTagsCount?: number;
  scrollOffset: number;
  withPlaceholder?: boolean;
}

const ThemeItem = React.forwardRef<HTMLInputElement | null, ThemeItemProps>(
  (
    {
      theme,
      onRemove,
      setValue,
      withPlaceholder = false,
      tagSampleCounter,
      totalTagsCount,
      scrollOffset,
    },
    ref
  ) => {
    const [width, setWidth] = useState(0);
    const spanRef = useRef<HTMLSpanElement | null>(null);

    useEffect(() => {
      if (spanRef?.current) {
        spanRef.current.textContent = theme.name;
        const width = spanRef.current.offsetWidth;
        setWidth(width + 8);
      }
    }, [spanRef, theme]);

    const changeHandler = (evt: any) => {
      if (!spanRef?.current) return;

      const val = evt.target.value;
      spanRef.current.textContent = val || evt.target.placeholder;
      setWidth(spanRef.current.offsetWidth + 8);
      setValue({ ...theme, name: val });
    };

    return (
      <ItemContainer>
        <HiddenSpan ref={spanRef} />
        <HeaderContainer>
          <TitleContainer>
            <NameInput
              style={{
                width,
              }}
              type='text'
              autoFocus
              ref={ref}
              value={theme.name}
              onChange={changeHandler}
            />
            {tagSampleCounter && totalTagsCount ? (
              <ThemeRatioContainer>
                <ThemeRatioLabel>
                  {Math.round((100 * tagSampleCounter) / totalTagsCount)}%
                </ThemeRatioLabel>
              </ThemeRatioContainer>
            ) : (
              ''
            )}
          </TitleContainer>
          <Tooltip
            scrollOffset={scrollOffset}
            shiftLeft
            shiftRight={45}
            toolTipText='Delete theme'
          >
            <TrashButtonWithActive
              icon={<TrashIcon />}
              onClick={() => onRemove(theme)}
            />
          </Tooltip>
        </HeaderContainer>
        <Instructions
          value={theme.instructions ?? ''}
          onChange={(e) => setValue({ ...theme, instructions: e.target.value })}
          placeholder={
            withPlaceholder
              ? 'Add your free text coding instructions here. For example instructions can be: including …, excluding …, for example …'
              : undefined
          }
        />
      </ItemContainer>
    );
  }
);

const HeaderContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ThemeRatioContainer = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${Colors.P30};
`;

const ThemeRatioLabel = styled.div`
  color: ${Colors.B100};
  font-family: ${FontFamily.ROBOTO};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const TitleContainer = styled(HorizontalFlex)``;

const ItemContainer = styled(CenteredVerticalFlex)`
  flex-shrink: 0;
  border-radius: 16px;
  background: ${Colors.B10};
  padding: 16px;
  align-items: stretch;
`;

const Instructions = styled.textarea`
  font-family: ${FontFamily.ROBOTO};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${Colors.B100};
  height: 36px;
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${Colors.B50};
  resize: none;
  outline: none;
  background-color: transparent;
  &::placeholder {
    color: ${Colors.B30};
  }
`;

const textStyle = `
  font-family: ${FontFamily.ROBOTO};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const NameInput = styled.input`
  ${textStyle};
  color: ${Colors.B100};
  margin-right: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  min-width: 50px;
  max-width: 320px;
  box-sizing: border-box;
`;

const HiddenSpan = styled.span`
  ${textStyle};
  visibility: hidden;
  position: fixed;
  white-space: pre;
  box-sizing: border-box;
`;

const TrashButtonWithActive = styled(ButtonWithHover)`
  padding: 0;
  > svg rect {
    stroke: transparent;
  }
  &:hover > svg rect {
    stroke: ${Colors.B20};
  }
  &:active > svg path {
    stroke: ${Colors.P100};
  }
  &:active > svg rect {
    stroke: transparent;
  }
  &:active > svg {
    background-color: transparent;
  }
`;

export default ThemeItem;
