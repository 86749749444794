import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doesSessionExist } from 'supertokens-auth-react/recipe/session';

const RootPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const func = async () => {
      const isLoggedIn = await doesSessionExist();
      const env = import.meta.env.VITE_ENVIRONMENT;
      if (!isLoggedIn) {
        if (env === 'production') {
          window.location.href = 'https://home.blix.ai';
        } else {
          navigate('/projects');
        }
      } else {
        navigate('/projects');
      }
    };
    func();
  }, []);

  return null;
};

export default RootPage;
