import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import ErrorDivStyle from './ErrorDivStyle';
import Label from './Label';
import Separator from './Seperator';
import StyledInput from './StyledInput';

export interface TextInputWithLabelProps {
  label: string;
  placeholder?: string;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  className?: string;
  disabled?: boolean;
  inputType?: string;
  maxLength?: number;
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: any;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const TextInputWithLabel: React.FC<TextInputWithLabelProps> = ({
  label,
  placeholder,
  onFocus,
  onBlur,
  className,
  disabled,
  inputType,
  maxLength,
  value,
  onChange,
  error,
  onKeyDown,
}) => {
  const [active, setActive] = useState(false);

  return (
    <Container className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        autoComplete='off'
        onFocus={() => {
          setActive(true);
          onFocus?.();
        }}
        onBlur={() => {
          setActive(false);
          onBlur?.();
        }}
        placeholder={placeholder}
        disabled={disabled}
        type={inputType}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={onKeyDown}
      />
      <Separator $active={active} />
      {error ? <ErrorDiv>{error.message}</ErrorDiv> : null}
    </Container>
  );
};

export default TextInputWithLabel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  color: ${Colors.PRIMARY};
`;

const ErrorDiv = styled.div`
  ${ErrorDivStyle}
`;
