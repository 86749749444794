import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import CheckIcon from '../../../assets/check.svg';
import { Colors } from '../../../theme/Colors';
import HighlightedText from './HighlightedText';

interface RowProps {
  label: string;
  selected: boolean;
  onClick: VoidFunction;
  highlightText?: string;
  highlightRow?: boolean;
}

const Row: React.FC<PropsWithChildren<RowProps>> = ({
  label,
  selected,
  onClick,
  highlightText,
  highlightRow = false,
}) => {
  return (
    <Container onClick={onClick} $highlightRow={highlightRow}>
      <HighlightedText
        selected={selected}
        text={label}
        highlightText={highlightText}
      />
      {selected && <img src={CheckIcon} />}
    </Container>
  );
};

export default Row;

const Container = styled.div<{ $highlightRow: boolean }>`
  background-color: ${({ $highlightRow }) =>
    $highlightRow ? Colors.CATEGORYֹֹֹ_SELECTED_BACKGROUND : 'white'};
  display: flex;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
`;
