import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { uniqWith } from '../../utils/tools';
import Label from '../Label';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import SelectInput from '../form/select/SelectInput';

interface CohortsFilterProps {
  dashboardData: any;
  setThemesData: (themes?: any[]) => void;
  setResponseCount: (count?: number) => void;
}

const mapObjectToLowerCaseKeys = (obj: any) => {
  const lowerCaseKeys = Object.keys(obj).reduce((acc: any, key: string) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});
  return lowerCaseKeys;
};

const CohortsFilter: React.FC<CohortsFilterProps> = ({
  dashboardData,
  setThemesData,
  setResponseCount,
}) => {
  const [filterType, setFilterType] = useState<string>('owner');
  const [filterValues, setFilterValues] = useState<string[] | undefined>();
  const [filterByValue, setFilterByValue] = useState<string>('');

  useEffect(() => {
    if (!dashboardData || !filterType) return;

    const allMetadataTypes = dashboardData.themes
      .flatMap((theme: any) => theme.user_contents)
      .map((uc: any) => uc.metadata);
    const uniqueMetadataValues: string[] = Array.from(
      new Set(
        allMetadataTypes
          .map((a: any) => JSON.parse(a))
          .map(mapObjectToLowerCaseKeys)
          .map((a: any) => a[filterType])
      )
    );
    setFilterValues(uniqueMetadataValues);
  }, [filterType, dashboardData]);

  useEffect(() => {
    if (!dashboardData) return;

    if (!filterType || !filterByValue) {
      setThemesData(dashboardData.themes);
      setResponseCount(dashboardData.respondents_count);
      return;
    }

    const filteredThemes = dashboardData.themes.map((t: any) => ({
      ...t,
      user_contents: t.user_contents.filter(
        (uc: any) =>
          mapObjectToLowerCaseKeys(JSON.parse(uc.metadata))[filterType] ==
          filterByValue
      ),
    }));

    const allContentIds = filteredThemes
      .flatMap((theme: any) => theme.user_contents)
      .map((uc: any) => uc.id);

    const uniqueContentIds = uniqWith(
      allContentIds,
      (a: any, b: any) => a === b
    );

    setResponseCount(uniqueContentIds.length);
    setThemesData(filteredThemes);
  }, [
    filterByValue,
    dashboardData,
    filterType,
    setThemesData,
    setResponseCount,
  ]);

  const handleSelectFilterType = (event: any) => {
    setFilterType(event.target.value);
  };

  const handleSelectFilterValue = (value: string) => {
    setFilterByValue(value);
  };

  return (
    <Container>
      <StyledLabel>Filter by owner:</StyledLabel>
      <select
        style={{ display: 'none' }}
        value={filterType}
        onChange={handleSelectFilterType}
      >
        <option disabled value={''}>
          Please select metadata to filter by
        </option>
        {dashboardData.column_definitions.map(
          (metadataType: any, index: number) => (
            <option key={index} value={metadataType.title}>
              {metadataType.title}
            </option>
          )
        )}
      </select>
      {filterValues ? (
        <Select
          label=''
          value={filterByValue}
          onChange={handleSelectFilterValue}
          options={[
            {
              label: 'All',
              value: '',
            },
          ].concat(
            filterValues.map((filter: string) => ({
              label: filter,
              value: filter,
            }))
          )}
          cardStyle={{ maxHeight: 400, overflowY: 'scroll' }}
        />
      ) : null}
    </Container>
  );
};

export default CohortsFilter;

const Container = styled(CenteredHorizontalFlex)`
  margin-bottom: 16px;
  margin-left: 50px;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  font-size: 16px;
  /* color: ${Colors.PRIMARY}; */
`;

const Select = styled(SelectInput)`
  width: 90px;
`;
