import { Colors } from '../../../theme/Colors';
import { FontFamily } from '../../../theme/FontFamily';

export const buttonStyle = `text-align: initial;
  padding: 0;
  margin: 6px 0 6px 6px;
  border: none;
  outline: none;
  color: ${Colors.GRAY_100};
  font-size: 18px;
  line-height: 24px;
  font-family: ${FontFamily.ROBOTO};
  background-color: transparent;`;

export const placeholderTextStyle = `font-size: 12px;
  line-height: 24px;
  color: ${Colors.GRAY_60};`;

export const containerStyle = `display: flex;
  flex-direction: column;
  justify-content: center;`;
