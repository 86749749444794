import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { DashboardThemeItem } from '../../types';
import SectionThemesHorizontalGraph from './HorizontalGraph';
import ThemeDetails from './ThemeDetails';

interface SectionProps {
  themes: DashboardThemeItem[];
  respondentsCount: number;
  layoutDirection: 'LTR' | 'RTL';
  userContentsLayoutDirection: 'LTR' | 'RTL';
}

const Section: React.FC<PropsWithChildren<SectionProps>> = ({
  themes,
  respondentsCount,
  layoutDirection,
  userContentsLayoutDirection,
}) => {
  const histogram = themes.map((theme) => ({
    theme: theme.name,
    count: theme.user_contents.length ? theme.user_contents.length : 0,
  }));

  themes.sort((a, b) => b.user_contents.length - a.user_contents.length);
  const theme_details_rows = themes.slice(0, 10).map((theme) => {
    const percentage = theme.user_contents.length
      ? Math.floor((theme.user_contents.length / respondentsCount) * 100)
      : 0;
    return (
      <ThemeDetails
        key={theme.name}
        themeName={theme.name}
        percentage={percentage}
        section_summary={theme.summary}
        citations={theme.user_contents?.slice(0, 10).map((a) => a.content)}
        layoutDirection={layoutDirection}
        userContentsLayoutDirection={userContentsLayoutDirection}
      />
    );
  });

  return (
    <Container>
      <SectionThemesHorizontalGraph
        histogram={histogram}
        respondentsCount={respondentsCount}
      />
      {theme_details_rows}
    </Container>
  );
};

export default Section;

const Container = styled.div`
  margin-bottom: 20px;
`;
