import styled from 'styled-components';

const ProjectImage = styled.img`
  border-radius: 10px;
  width: 42px;
  height: 42px;
  object-fit: cover;

  :hover {
    transform: scale(1.03);
    transition: all 0.2s ease-out;
  }

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
`;

export default ProjectImage;
