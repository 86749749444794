import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../theme/Colors';
import { FontFamily } from '../../../theme/FontFamily';
import { escapeRegExp } from '../../../utils/utils';

interface HighlightedTextProps {
  text: string;
  selected?: boolean;
  highlightText?: string;
  labelClassName?: string;
  className?: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = ({
  text,
  selected,
  highlightText,
  labelClassName,
  className,
}) => {
  if (
    !highlightText ||
    !new RegExp(escapeRegExp(highlightText), 'i').test(text) ||
    selected
  ) {
    return (
      <Container className={className}>
        <Label className={labelClassName} selected={selected}>
          {text}
        </Label>
      </Container>
    );
  }

  const startIndex = text.search(new RegExp(escapeRegExp(highlightText), 'i'));
  const endIndex = startIndex + highlightText.length;

  const first = text.slice(0, startIndex);
  const middle = text.slice(startIndex, endIndex);
  const last = text.slice(endIndex);

  return (
    <Container className={className}>
      <Label className={labelClassName}>
        {first}
        <Bold>{middle}</Bold>
        {last}
      </Label>
    </Container>
  );
};

export default HighlightedText;

const Container = styled.div`
  display: flex;
`;

const BodyTextMedium = styled.span`
  color: ${Colors.GRAY_40};
  font-size: 16px;
  line-height: 24px;
  font-family: ${FontFamily.ROBOTO};
`;

const Label = styled(BodyTextMedium)<{ selected?: boolean }>`
  color: ${({ selected }) => (selected ? Colors.PRIMARY : Colors.GRAY_40)};
`;

const Bold = styled(Label)`
  font-family: ${FontFamily.ROBOTO};
`;
