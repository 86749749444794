import React, { useState } from 'react';
import styled from 'styled-components';
import { ColumnDefinitionDraft, ColumnType, JobDisplayData } from '../../types';
import { GENERAL_ERROR_MESSAGE } from '../../utils/constants';
import Separator from '../Seperator';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import ErrorModal from '../modal/ErrorModal';
import P2 from '../newTextComponents/P2';
import ColumnMapperItem from './ColumnMapperItem';

interface ColumnMapperProps {
  columnDefs?: ColumnDefinitionDraft[];
  jobsDisplay?: JobDisplayData[];
  setColumnDefs: (columnDefs: ColumnDefinitionDraft[]) => void;
  className?: string;
}

const ColumnMapper: React.FC<ColumnMapperProps> = ({
  columnDefs,
  jobsDisplay,
  setColumnDefs,
  className,
}) => {
  const [updateError, setUpdateError] = useState<string | undefined>();
  const updateColumnDef =
    (index: number) => (newColumnDef: ColumnDefinitionDraft) => {
      if (!columnDefs) return;

      const targetColumnDef = columnDefs[index];
      if (targetColumnDef.columnType === ColumnType.OpenEnded) {
        const job = jobsDisplay?.find(
          (job) => job.questionId === targetColumnDef.questionId
        );
        if (job?.status && job?.status !== 'Not Analyzed') {
          setUpdateError(
            'Cannot change Open Ended column type after analysis has started. contact admin for more details.'
          );
          return;
        }
      }

      const updatedColumnDefs = [...columnDefs];
      updatedColumnDefs[index] = newColumnDef;
      setColumnDefs(updatedColumnDefs);
    };

  const getIsCheckboxDisabled = (columnDef: ColumnDefinitionDraft) => {
    if (columnDef.columnType === ColumnType.OpenEnded) {
      const job = jobsDisplay?.find(
        (job) => job.questionId === columnDef.questionId
      );

      return job?.status && job?.status !== 'Not Analyzed';
    }
  };
  return (
    <Container className={className}>
      <TableHeader>
        {/* <IDHeader>ID</IDHeader> */}
        <QuestionHeader>Column Title</QuestionHeader>
        <PreviewHeader>Preview</PreviewHeader>
        <OpenHeader>Open Ended</OpenHeader>
      </TableHeader>
      <Separator />
      <TableBodyContainer>
        {columnDefs?.map((columnDef, index) => (
          <div key={index}>
            <ColumnMapperItem
              columnDef={columnDef}
              onChangeColumnDef={updateColumnDef(index)}
              isCheckboxDisabled={getIsCheckboxDisabled(columnDef)}
            />
            <Separator />
          </div>
        ))}
      </TableBodyContainer>
      <ErrorModal
        show={!!updateError}
        onClose={() => setUpdateError(undefined)}
        title={'Update Column Definitions Failed'}
        subTitle={updateError || GENERAL_ERROR_MESSAGE}
      />
    </Container>
  );
};

export default ColumnMapper;

const Container = styled(VerticalFlex)`
  width: 1170px;
`;

const TableBodyContainer = styled(VerticalFlex)`
  /* padding: 0 12px; */
`;

const TableHeader = styled(HorizontalFlex)`
  padding: 16px 12px;
`;

const ColumnHeader = styled(P2)`
  font-weight: 500;
`;

// const IDHeader = styled(ColumnHeader)`
//   flex: 1;
// `;
const QuestionHeader = styled(ColumnHeader)`
  flex: 4;
`;
const PreviewHeader = styled(ColumnHeader)`
  flex: 4;
`;
const OpenHeader = styled(ColumnHeader)`
  flex: 2;
`;
