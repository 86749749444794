import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import { FontFamily } from '../theme/FontFamily';

const Title = styled.div`
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.GRAY_100};
  margin-bottom: 12px;
  text-align: center;
`;

export default Title;
