import styled from 'styled-components';

const DropArea = styled.div<{ $active?: boolean }>`
  position: relative;
  margin-top: 64px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  text-align: center;
  ${({ $active }) => ($active ? 'cursor: pointer' : 'cursor: default')};
  background-color: aliceblue;
  height: 400px;
  width: 500px;
  margin-bottom: 49px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default DropArea;
