import React, { useEffect, useRef, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Colors } from '../../theme/Colors';
import TooltipBoundingRect from '../buttons/TooltipBoundingRect';
import P2 from '../newTextComponents/P2';

interface PreviewWithTooltipProps {
  text?: string;
  previewContainerStyle?: CSSProperties;
  previewTextStyle?: CSSProperties;
}

const PreviewWithTooltip: React.FC<PreviewWithTooltipProps> = ({
  text,
  previewContainerStyle,
  previewTextStyle,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      setHasOverflow(ref?.current?.scrollWidth > ref?.current?.clientWidth);
    }
  }, [ref]);

  return (
    <Container>
      <TooltipBoundingRect
        disabled={true || !hasOverflow}
        toolTipText={text ?? ''}
        shiftRight={-50}
      >
        <PreviewTextOverflowConainer ref={ref} style={previewContainerStyle}>
          <Preview style={previewTextStyle}>{text}</Preview>
        </PreviewTextOverflowConainer>
      </TooltipBoundingRect>
    </Container>
  );
};

export default PreviewWithTooltip;

const Container = styled.div``;

const PreviewTextOverflowConainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
`;

const Preview = styled(P2)`
  color: ${Colors.B60};
`;
