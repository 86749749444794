import styled from 'styled-components';
import { Colors } from '../../../theme/Colors';

const Card = styled.div`
  margin-top: 7px;
  box-shadow: 0px 2px 12px rgba(45, 45, 45, 0.1);
  border-radius: 10px;
  max-height: 272px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${Colors.BORDER};
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.BORDER};
    border-radius: 100px;
  }
`;

export default Card;
