import React, { ReactElement } from 'react';
import styled from 'styled-components';
import XIcon from '../../assets/x_icon.svg';
import Button from '../buttons/Button';
interface ModalProps {
  children: ReactElement;
  onClose: VoidFunction;
  hidden?: boolean;
  withCloseButton?: boolean;
  className?: string;
  topModal?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  hidden,
  withCloseButton = false,
  className,
  topModal = false,
}) => {
  return (
    <Container
      $topModal={topModal}
      hidden={hidden}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <Content className={className} onClick={(e) => e.stopPropagation()}>
        {withCloseButton && (
          <CancelButton onClick={onClose}>
            <img src={XIcon} alt='Your SVG' />
          </CancelButton>
        )}
        {children}
      </Content>
    </Container>
  );
};

export default Modal;

const CancelButton = styled(Button)`
  position: absolute;
  inset-block-start: 24px;
  inset-inline-start: 20px;
  z-index: 1000;
`;

const Container = styled.div<{ hidden?: boolean; $topModal: boolean }>`
  overscroll-behavior: contain;
  box-sizing: border-box;
  display: flex;

  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;

  ${({ hidden }) => (hidden ? 'display: none; opacity: 0;' : '')}
  ${({ $topModal }) => ($topModal ? 'z-index: 10000;' : 'z-index: 9999;')}
`;

const Content = styled.div`
  background-color: #fefefe;
  margin: auto;
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 12px;
  align-items: center;
`;
