import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import ErrorDivStyle from './ErrorDivStyle';
import Label from './Label';
import Separator from './Seperator';
import StyledInput from './StyledInput';

export interface TextInputWithLabelProps {
  label: string;
  placeholder?: string;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  inputType?: string;
  maxLength?: number;
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: any;
}

const TextInputWithLabelForwardRef = forwardRef<
  HTMLInputElement,
  TextInputWithLabelProps
>(
  (
    {
      label,
      placeholder,
      onFocus,
      onBlur,
      onKeyDown,
      className,
      disabled,
      inputType,
      maxLength,
      value,
      onChange,
      error,
    },
    ref
  ) => {
    const [active, setActive] = useState(false);

    return (
      <Container className={className}>
        <StyledLabel>{label}</StyledLabel>
        <StyledInput
          ref={ref}
          autoComplete='off'
          onFocus={() => {
            setActive(true);
            onFocus?.();
          }}
          onBlur={() => {
            setActive(false);
            onBlur?.();
          }}
          placeholder={placeholder}
          disabled={disabled}
          type={inputType}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={onKeyDown}
        />
        <Separator $active={active} />
        {error ? <ErrorDiv>{error.message}</ErrorDiv> : null}
      </Container>
    );
  }
);

export default TextInputWithLabelForwardRef;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  color: ${Colors.PRIMARY};
`;

const ErrorDiv = styled.div`
  ${ErrorDivStyle}
`;
