import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';

interface PrimaryButtonProps {
  disabled?: boolean;
  loading?: boolean;
  label: string;
  onClick?: VoidFunction;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  className?: string;
  icon?: ReactElement;
  withClearBackground?: boolean;
  withoutBorder?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  onClick,
  disabled = false,
  loading = false,
  label,
  style,
  textStyle,
  className,
  icon,
  withClearBackground,
  withoutBorder,
  type = 'button',
}) => {
  return (
    <Button
      className={className}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      // hidden={disabled}
      disabled={disabled || loading}
      type={type}
      $withClearBackground={withClearBackground}
      $withoutBorder={withoutBorder}
      aria-label={'submit-button'}
    >
      <TextLabel style={textStyle} $withClearBackground={withClearBackground}>
        {label}
      </TextLabel>
      {!loading && icon && <IconContainer>{icon}</IconContainer>}
    </Button>
  );
};

export default PrimaryButton;

const Button = styled.button<{
  $withClearBackground?: boolean;
  $withoutBorder?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  height: 39px;
  display: flex;
  padding: 8px 24px;
  gap: 10px;
  background-color: ${({ $withClearBackground }) =>
    $withClearBackground ? Colors.WHITE : Colors.PRIMARY};
  ${({ $withoutBorder }) =>
    $withoutBorder ? `background-color: transparent` : ''};
  border-radius: 60px;
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
  ${({ disabled }) =>
    disabled
      ? `background-color: ${Colors.PRIMARY_DISABLED}; cursor: default;`
      : 'cursor: pointer;'};

  ${({ $withClearBackground, $withoutBorder }) =>
    $withClearBackground && !$withoutBorder
      ? `border: 1px solid  ${Colors.PRIMARY};`
      : ''}
  ${({ $withClearBackground }) =>
    $withClearBackground ? 'box-sizing: border-box;' : ''}
  ${({ $withClearBackground }) =>
    $withClearBackground ? 'border-radius: 100px;' : ''} /* :hover {
    background-color: ${Colors.PRIMARY_DISABLED};
  } */

  &:focus, &:focus-visible {
    outline: none;
  }
`;

const TextLabel = styled.span<{
  $withClearBackground?: boolean;
}>`
  color: ${({ $withClearBackground }) =>
    $withClearBackground ? Colors.PRIMARY : Colors.WHITE};
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const IconContainer = styled.div`
  margin-inline-end: 6px;
  margin-bottom: -2px;
`;
