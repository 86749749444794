import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { ColumnDefinitionDraft, ColumnType } from '../../types';
import TooltipBoundingRect from '../buttons/TooltipBoundingRect';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import PreviewWithTooltip from '../preview/PreviewWithTooltip';

interface ColumnMapperItemProps {
  columnDef: ColumnDefinitionDraft;
  onChangeColumnDef: (columnDef: ColumnDefinitionDraft) => void;
  isCheckboxDisabled?: boolean;
}

const ColumnMapperItem: React.FC<ColumnMapperItemProps> = ({
  columnDef,
  onChangeColumnDef,
  isCheckboxDisabled,
}) => {
  // const [showMenu, setShowMenu] = useState(false);

  const updateCheckbox = () => {
    const temp = { ...columnDef };
    temp.columnType =
      temp.columnType == ColumnType.OpenEnded
        ? ColumnType.ClosedEnded
        : ColumnType.OpenEnded;
    onChangeColumnDef(temp);
  };

  return (
    <Container
      $checked={columnDef.columnType === ColumnType.OpenEnded}
      onClick={updateCheckbox}
    >
      <ColumnTitleContainer>
        <PreviewWithTooltip
          text={columnDef.userDefinedTitle ?? columnDef.columnHeader}
          previewContainerStyle={{ paddingRight: 64, maxWidth: '100%' }}
          previewTextStyle={{ color: Colors.B100 }}
        />
      </ColumnTitleContainer>
      <PreviewContainer>
        <PreviewWithTooltip text={columnDef.preview} />
      </PreviewContainer>
      <ColumnTypeContainer>
        <TooltipBoundingRect
          disabled={true || !isCheckboxDisabled}
          toolTipText={
            'Cannot change Open Ended column type after analysis has started'
          }
        >
          <Checkbox
            type='checkbox'
            disabled={isCheckboxDisabled}
            onChange={updateCheckbox}
            checked={columnDef.columnType == ColumnType.OpenEnded}
          />
        </TooltipBoundingRect>
      </ColumnTypeContainer>
      {/* <ColumnMapperItemMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        onExcludeColumn={() => setShowMenu(false)}
      /> */}
    </Container>
  );
};

export default ColumnMapperItem;

const Container = styled(CenteredHorizontalFlex)<{ $checked?: boolean }>`
  ${({ $checked }) => ($checked ? `background-color: ${Colors.P20};` : '')}
  &:hover {
    ${({ $checked }) => (!$checked ? `background-color: ${Colors.B10};` : '')}
  }

  height: 52px;
  justify-content: space-between;
  position: relative;
  &:hover > .cm-menu-icon-visible {
    // Targeting the child component on hover
    opacity: 1;
    visibility: visible;
  }
  &:hover .cm-edit-icon-visible {
    // Targeting an decendent child component on hover
    opacity: 1;
    visibility: visible;
  }
`;

const ColumnTitleContainer = styled.div`
  flex: 4;
  padding-left: 12px;
`;

const PreviewContainer = styled.div`
  flex: 4;
  overflow: hidden;
`;

const ColumnTypeContainer = styled(HorizontalFlex)`
  flex: 2;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-color: ${Colors.B30};
  accent-color: ${Colors.P100};
`;
