import React, { ReactElement } from 'react';
import TransparentButton from './TransparentButton';

interface ButtonWithIconProps {
  icon: ReactElement;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  icon,
  onClick,
  disabled,
  className,
}) => {
  return (
    <TransparentButton
      disabled={disabled}
      className={className}
      onClick={onClick}
      type='button'
    >
      {icon}
    </TransparentButton>
  );
};

export default ButtonWithIcon;
