import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

export type ButtonOnClickHandler =
  | MouseEventHandler<HTMLButtonElement>
  | undefined;

export interface ButtonProps {
  type?: 'submit' | 'reset' | 'button' | undefined;
  disabled?: boolean;
  onClick: ButtonOnClickHandler;
  className?: string;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  disabled = false,
  onClick,
  className,
  children,
}) => {
  return (
    <StyledButton
      className={className}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
