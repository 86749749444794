import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import MultiFactorAuth from 'supertokens-auth-react/recipe/multifactorauth';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import Session from 'supertokens-auth-react/recipe/session';
import ThirdParty, { Google } from 'supertokens-auth-react/recipe/thirdparty';
import logger from '../utils/logger';
import SecondFactorClaim from './SecondFactorClaim';

export const initSuperTokens = () =>
  SuperTokens.init({
    enableDebugLogs: import.meta.env.VITE_ENVIRONMENT !== 'production',
    // enableDebugLogs: true,
    appInfo: {
      // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
      appName: import.meta.env.VITE_SUPERTOKENS_APP_NAME,
      apiDomain: import.meta.env.VITE_SUPERTOKENS_API_DOMAIN,
      websiteDomain: import.meta.env.VITE_SUPERTOKENS_WEBSITE_DOMAIN,
      apiBasePath: import.meta.env.VITE_SUPERTOKENS_API_BASE_PATH,
      websiteBasePath: import.meta.env.VITE_SUPERTOKENS_WEBSITE_BASE_PATH,
    },
    languageTranslations: {
      translations: {
        en: {
          PWLESS_USER_INPUT_CODE_HEADER_TITLE: 'Email Verification',
          PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE:
            'Please enter the 6-digit verification code that was sent to ',
          PWLESS_USER_INPUT_CODE_INPUT_LABEL: 'Verification Code',
          GENERAL_ERROR_OTP_UNDEFINED: 'Please fill your Code',
          GENERAL_ERROR_OTP_INVALID: 'Invalid Code',
          GENERAL_ERROR_OTP_EXPIRED: 'Expired Code.',
          GENERAL_ERROR_OTP_NON_STRING: 'Code must be of type string',
          GENERAL_ERROR_OTP_EMPTY: 'Code cannot be empty',
        },
      },
      /*
       * This optional property sets the default and fallback language.
       * It can be any string that will be used to fetch translations from the above object.
       * Defaults to "en"
       */
      // defaultLanguage: 'he',
    },
    recipeList: [
      EmailVerification.init({
        mode: 'REQUIRED', // or "OPTIONAL"
      }),
      Passwordless.init({
        contactMethod: 'EMAIL',
        onHandleEvent: (context) => {
          logger.info('Passwordless onHandleEvent context: ', context);
          if (context.action === 'PASSWORDLESS_CODE_SENT') {
            // this event is fired when the user has successfully sent out an OTP email / SMS
          } else if (context.action === 'PASSWORDLESS_RESTART_FLOW') {
            // This event is fired when the user's OTP has expired, or
            // they have reached the max limit of number of failed OTP attempts.
          } else if (
            context.action === 'SUCCESS' &&
            !context.createdNewSession
          ) {
            // this event is fired when successfully completing the OTP email / SMS challenge
            // and if it's not used in first factor (cause we do !context.createdNewSession)
          }
        },
        style: `
            [data-supertokens="headerSubtitle secondaryText"] > br {
                    display: none;
                }
            `,
      }),
      MultiFactorAuth.init({
        firstFactors: [
          MultiFactorAuth.FactorIds.EMAILPASSWORD,
          MultiFactorAuth.FactorIds.THIRDPARTY,
        ],
      }),
      EmailPassword.init({
        onHandleEvent: async (context) => {
          logger.info('EmailPassword onHandleEvent context: ', context);
          if (context.action === 'SUCCESS') {
            // let { id, emails } = context.user;
            if (
              context.isNewRecipeUser &&
              context.user.loginMethods.length === 1
            ) {
              // TODO: Sign up
            } else {
              // TODO: Sign in
            }
          }
        },
        getRedirectionURL: async (context) => {
          logger.info('EmailPassword getRedirectionURL context: ', context);
          if (context.action === 'RESET_PASSWORD') {
            return '/auth/reset-password?rid=thirdpartyemailpassword';
          }

          return '/projects';
        },
      }),
      ThirdParty.init({
        signInAndUpFeature: {
          providers: [Google.init()],
        },
        style: `
            [data-supertokens~=formRow] > [data-supertokens~=button] {
                    background-color: rgba(107,87,199, 0.8);
                    border-color: rgba(107,87,199, 0.8);
                }
            `,
        onHandleEvent: async (context) => {
          if (context.action === 'SUCCESS') {
            // const { id, emails } = context.user;

            if (
              context.isNewRecipeUser &&
              context.user.loginMethods.length === 1
            ) {
              logger.info('ThirdParty onHandleEvent Sign up ', context);
            } else {
              logger.info('ThirdParty onHandleEvent Sign in ', context);
            }
          }
        },
        getRedirectionURL: async (context) => {
          logger.info('ThirdParty getRedirectionURL context: ', context);

          return '/projects';
        },
        postAPIHook: async (context) => {
          const json = await context.fetchResponse.json();

          if (
            json?.status === 'GENERAL_ERROR' &&
            json?.message === 'User not in whitelist'
          ) {
            logger.error('ThirdParty postAPIHook User not in whitelist');
          }
        },
      }),
      Session.init({
        override: {
          functions: (oI) => ({
            ...oI,
            onHandleEvent: async (context: any) => {
              // debugger;
              logger.info('Session onHandleEvent context: ', context);
              if (context.action === 'UNAUTHORISED') {
                logger.error('Session onHandleEvent UNAUTHORISED');
                alert(
                  'Authentication failed. Please login again or contact admin for more details.'
                );
              }
            },
            getGlobalClaimValidators: ({
              claimValidatorsAddedByOtherRecipes,
            }) => {
              return [
                SecondFactorClaim.validators.isTrue(),
                ...claimValidatorsAddedByOtherRecipes.filter(
                  (v) => v.id !== MultiFactorAuth.MultiFactorAuthClaim.id
                ),
              ];
            },
          }),
        },
      }),
    ],
  });
