import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '../assets/check.svg';
import EditIcon from '../assets/edit.svg';
import { Colors } from '../theme/Colors';
import ErrorDivStyle from './ErrorDivStyle';
import StyledInput from './StyledInput';
import CenteredHorizontalFlex from './containers/CenteredHorizontalFlex';
import VerticalFlex from './containers/VerticalFlex';

export interface TextInputWithIconProps {
  placeholder?: string;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  className?: string;
  disabled?: boolean;
  inputType?: string;
  maxLength?: number;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: any;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  inputElementStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  maxWidth?: number;
  withCheckMark?: boolean;
}

const TextInputWithIcon: React.FC<TextInputWithIconProps> = ({
  placeholder,
  onFocus,
  onBlur,
  className,
  disabled = false,
  inputType,
  maxLength,
  value,
  onChange,
  error,
  onKeyDown,
  inputElementStyle,
  containerStyle,
  maxWidth,
  withCheckMark,
}) => {
  const [active, setActive] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      inputRef.current?.blur();
      setActive(false);
    }

    onKeyDown?.(event);
  };

  return (
    <Container className={className} style={containerStyle}>
      <ContentsContainer>
        <Input
          ref={inputRef}
          style={{ ...inputElementStyle, maxWidth }}
          autoComplete='off'
          onFocus={() => {
            setActive(true);
            onFocus?.();
          }}
          onBlur={() => {
            setActive(false);
            onBlur?.();
          }}
          placeholder={placeholder}
          disabled={disabled}
          type={inputType}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleKeyDown}
        />
        {disabled || active ? null : (
          <Icon
            $active={active}
            $disabled={disabled}
            className='cm-edit-icon-visible'
            src={EditIcon}
            onClick={() => inputRef.current?.focus()}
          />
        )}
        {active && withCheckMark ? (
          <Icon
            $active={active}
            $disabled={disabled}
            // className='cm-edit-icon-visible'
            src={CheckIcon}
            onClick={() => inputRef.current?.focus()}
          />
        ) : null}
      </ContentsContainer>
      <Separator $active={active} $maxWidth={maxWidth} />
      {error ? <ErrorDiv>{error.message}</ErrorDiv> : null}
    </Container>
  );
};

export default TextInputWithIcon;

const Container = styled(VerticalFlex)`
  align-items: stretch;
`;

const ContentsContainer = styled(CenteredHorizontalFlex)`
  justify-content: space-between;
`;

const Input = styled(StyledInput)`
  font-size: 14px;
  line-height: 16px;
`;

const ErrorDiv = styled.div`
  ${ErrorDivStyle}
`;

const Separator = styled.div<{ $active?: boolean; $maxWidth?: number }>`
  height: 1px;
  width: 100%;
  ${({ $active }) => ($active ? `background-color: ${Colors.B30}` : '')};
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}px` : '')};
`;

const sharedHiddenStyle = `
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.7s, visibility 0.7s;
`;

const Icon = styled.img<{ $active?: boolean; $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  width: 20px;
  height: 20px;
  ${sharedHiddenStyle}
  ${({ $active }) => ($active ? 'opacity: 1; visibility: visible;' : '')}
`;
