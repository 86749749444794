import styled from 'styled-components';
import CenteredHorizontalFlex from './CenteredHorizontalFlex';


const ButtonsContainer = styled(CenteredHorizontalFlex)`
  margin-top: 36px;
  gap: 12px;
  justify-content: space-between;
`;

export default ButtonsContainer;