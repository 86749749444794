import { format } from 'date-fns';
import barChart1 from '../assets/photos/barChart1.png';
import barChart2 from '../assets/photos/barChart2.png';
import barChart3 from '../assets/photos/barChart3.png';
import bulb1 from '../assets/photos/bulb1.png';
import bulb2 from '../assets/photos/bulb2.png';
import chart1 from '../assets/photos/chart1.png';
import { default as laptop } from '../assets/photos/laptop.png';
import lens from '../assets/photos/lens.png';
import pie1 from '../assets/photos/pie1.png';
import pie2 from '../assets/photos/pie2.png';
import { JobDisplayData, JobStatus } from '../types';
export const getNonEmptySample = <T>(
  sampleSize: number = 1,
  data: T[],
  key: keyof T
): string[] => {
  return data
    .map((d) => d[key] as string)
    .filter((x) => !!x)
    .slice(0, sampleSize);
};

export const getNonEmptyRowCount = <T>(data: T[], key: keyof T): number => {
  return data.map((d) => d[key] as string).filter((x) => !!x).length;
};

export const formatDateString = (date: string) =>
  format(new Date(date), 'MMM dd, yyyy');

export const isColumnDefinitionEditDisabled = (job: JobDisplayData) =>
  job.status === JobStatus.PROCESSED ||
  job.status === JobStatus.ANALYZED ||
  job.status === JobStatus.PROCESSING ||
  job.status === JobStatus.FAILED;

export const imagesArray = [
  barChart1,
  barChart2,
  barChart3,
  bulb1,
  bulb2,
  chart1,
  laptop,
  lens,
  pie1,
  pie2,
];

export function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const pastelColors: string[] = [
  '#ffd1dc', // Pastel pink
  '#b4e1ff', // Pastel blue
  '#baffc9', // Pastel green
  '#ffb3ba', // Pastel red
  '#dcb0ff', // Pastel purple
  '#ffdfba', // Pastel orange
  '#fbcce7', // Pastel magenta
  '#a7fcb9', // Pastel light green
  '#ace7ff', // Pastel cyan
  '#fdd2b5', // Pastel peach
  '#c7c1ff', // Pastel lavender
  '#ffbacd', // Pastel coral
  '#e7e9d1', // Pastel gray-green
  '#d6e0f0', // Pastel light blue
];

export const getPastelColor = (i: number) =>
  pastelColors[i % pastelColors.length];

export const dateAndTimeFormatted = (date: string) => {
  const formattedDate = new Date(date).toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  const formattedDateParts = formattedDate.split(',');
  const formattedTime = formattedDateParts[1].trim();
  const dateParts = formattedDateParts[0].split(' ');
  const formattedDateString = `${dateParts[1]} ${dateParts[0]}, ${formattedTime}`;

  return formattedDateString;
};
