import { Theme } from '../types';

export const compareThemeLists = (themes1: Theme[], themes2: Theme[]) => {
  if (themes1.length !== themes2.length) {
    return false;
  }

  for (let i = 0; i < themes1.length; i++) {
    const t1 = themes1[i];
    const t2 = themes2[i];

    if (!areThemeIdsEqual(t1, t2)) {
      return false;
    }

    const differentNames = t1.name !== t2.name;
    const oneEmptyOrUndefinedAndOnePopulated =
      !!t1.instructions !== !!t2.instructions;
    const bothPopulatedWithDifferentValues =
      !!t1.instructions &&
      !!t2.instructions &&
      t1.instructions !== t2.instructions;

    if (
      differentNames ||
      oneEmptyOrUndefinedAndOnePopulated ||
      bothPopulatedWithDifferentValues
    ) {
      return false;
    }
  }

  return true;
};

export const areThemeIdsEqual = (t1: Theme, t2: Theme) => {
  if (t1.id !== undefined || t2.id !== undefined) {
    return t1.id === t2.id;
  }

  return t1.localId === t2.localId;
};
