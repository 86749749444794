import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import { JobDisplayData } from '../../types';
import Separator from '../Seperator';
import TooltipBoundingRect from '../buttons/TooltipBoundingRect';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import QuestionRow from './QuestionRow';

interface QuestionsListProps {
  projectId: string;
  jobs?: JobDisplayData[];
  setJobs: (jobsDisplays: JobDisplayData[]) => void;
  onClickJobAction: (job: JobDisplayData) => void;
  onReAnalyzeQuestion: (job: JobDisplayData) => void;
}

const QuestionsList: React.FC<QuestionsListProps> = ({
  projectId,
  jobs,
  setJobs,
  onClickJobAction,
  onReAnalyzeQuestion,
}) => {
  const updateJob = (index: number) => (newJob: JobDisplayData) => {
    if (!jobs) return;

    const updatedJobs = [...jobs];
    updatedJobs[index] = newJob;
    setJobs(updatedJobs);
  };

  return (
    <Container>
      <TableHeader>
        <IDHeaderContainer>
          <TooltipBoundingRect
            toolTipText={
              'this is the ID of the question that will later be used to identify it in the results file'
            }
            // shiftRight={-20}
          >
            <IDHeader>Question ID</IDHeader>
          </TooltipBoundingRect>
        </IDHeaderContainer>
        <QuestionHeader>Column Title</QuestionHeader>
        <PreviewHeader>Preview</PreviewHeader>
        <OpenHeader>Rows</OpenHeader>
        <Space></Space>
      </TableHeader>
      <Separator />
      <VerticalFlex>
        {jobs?.map((job, index) => (
          <div key={index}>
            <QuestionRow
              projectId={projectId}
              job={job}
              onChangeJob={updateJob(index)}
              onClickJobAction={onClickJobAction}
              onReAnalyzeQuestion={onReAnalyzeQuestion}
            />
            <Separator />
          </div>
        ))}
      </VerticalFlex>
    </Container>
  );
};

export default QuestionsList;

const Container = styled(VerticalFlex)`
  width: 1170px;
`;

const TableHeader = styled(HorizontalFlex)`
  padding: 16px 0 16px 12px;
`;

const Space = styled.div`
  flex: 2;
`;

const ColumnHeader = styled.div`
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.B100};
  font-weight: 500;
`;

const IDHeaderContainer = styled.div`
  flex: 1;
  margin-right: 16px;
`;

const IDHeader = styled(ColumnHeader)`
  /* flex: 1;
  margin-right: 16px; */
`;
const QuestionHeader = styled(ColumnHeader)`
  flex: 4;
`;
const PreviewHeader = styled(ColumnHeader)`
  flex: 4;
`;
const OpenHeader = styled(ColumnHeader)`
  flex: 2;
`;
