import React, { CSSProperties, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import TransparentButton from '../buttons/TransparentButton';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import P2 from '../newTextComponents/P2';

interface MenuItemTextButtonWithIconProps {
  text: string;
  icon: ReactElement;
  onClick: () => void;
  className?: string;
  textStyle?: CSSProperties;
  disabled?: boolean;
  withRightArrow?: boolean;
}

const MenuItemTextButtonWithIcon: React.FC<
  PropsWithChildren<MenuItemTextButtonWithIconProps>
> = ({
  text,
  icon,
  onClick,
  className,
  textStyle,
  disabled,
  withRightArrow,
  children,
}) => {
  return (
    <Button
      disabled={disabled}
      className={className}
      onClick={onClick}
      type='button'
    >
      <LeftContainer>
        <IconContainer>{icon}</IconContainer>
        <StyledLabel style={textStyle}>{text}</StyledLabel>
      </LeftContainer>
      {withRightArrow && <ChevronRightIcon fill={Colors.B40} />}
      {children ? (
        <ChildrenFloatConainer>{children}</ChildrenFloatConainer>
      ) : null}
    </Button>
  );
};

export default MenuItemTextButtonWithIcon;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
`;

const ChildrenFloatConainer = styled(VerticalFlex)`
  position: absolute;
  width: 222px;
  right: -226px;
  top: 0;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.7s, visibility 0.7s;
`;

const LeftContainer = styled(HorizontalFlex)`
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Button = styled(TransparentButton)<{ disabled?: boolean }>`
  flex: 1;
  position: relative;
  ${(props) => props.disabled && 'cursor: default; opacity: 0.2;'}
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  &:hover {
    background-color: ${Colors.GRAY_20};
  }
  &:hover > ${ChildrenFloatConainer} {
    opacity: 1;
    visibility: visible;
  }
  &:focus {
    outline: none;
  }
`;

const StyledLabel = styled(P2)``;
