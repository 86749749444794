import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import DownwardsIcon from '../../../assets/icons/downwards_arrow.svg';
import useOutsideClickHandler from '../../../hooks/useOutsideClickHandler';
import { Colors } from '../../../theme/Colors';
import { FontFamily } from '../../../theme/FontFamily';
import Card from '../../cards/Card';
import CenteredVerticalFlex from '../../containers/CenteredVerticalFlex';
import HorizontalFlex from '../../containers/HorizontalFlex';
import VerticalFlex from '../../containers/VerticalFlex';
import ButtonInputWithLabel from '../input/ButtonInputWithLabel';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

interface SelectInputProps {
  label: string;
  options: Option[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  cardStyle?: React.CSSProperties;
  onChange: (value: string) => void;
  value: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  options,
  className,
  placeholder,
  disabled,
  cardStyle,
  onChange,
  value,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(false);

  useOutsideClickHandler([cardRef, rowRef], () => setActive(false));

  const selectItem = (option: Option) => {
    onChange(option.value);
    setActive(false);
  };

  const onClickButton = () => {
    if (disabled) return;
    setActive((prev) => !prev);
  };

  return (
    <StyledContainer className={className}>
      <RowContainer disabled={disabled} ref={rowRef} onClick={onClickButton}>
        <ButtonInputWithLabel
          label={label}
          buttonText={value}
          onClick={() => {}}
          placeholder={placeholder}
          disabled={disabled}
        />
        <ImageContainer
          style={{ transform: active ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
          <StyledDownwardsIcon src={DownwardsIcon} disabled={disabled} />
        </ImageContainer>
      </RowContainer>
      {active && (
        <StyledCard ref={cardRef} style={cardStyle}>
          <CardContent>
            {options.map((option, index) => (
              <LabelContainer
                key={index}
                disabled={option.disabled}
                onClick={() => {
                  if (option.disabled) return;
                  selectItem(option);
                }}
              >
                <Label disabled={option.disabled}>{option.label}</Label>
              </LabelContainer>
            ))}
          </CardContent>
        </StyledCard>
      )}
    </StyledContainer>
  );
};

export default SelectInput;

const ImageContainer = styled(VerticalFlex)`
  justify-content: center;
  transition: transform 0.3s ease-in-out;
`;

const StyledDownwardsIcon = styled.img<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (!!disabled ? 'default' : 'pointer')};
`;

const RowContainer = styled(HorizontalFlex)<{ disabled?: boolean }>`
  min-height: 54px;
  justify-content: space-between;
  cursor: ${({ disabled }) => (!!disabled ? 'default' : 'pointer')};
`;

const StyledContainer = styled(CenteredVerticalFlex)`
  position: relative;
  align-items: stretch;
`;

const LabelContainer = styled(VerticalFlex)<{ disabled?: boolean }>`
  justify-content: center;
  /* height: 48px; */
  padding: 8px 8px;
  ${({ disabled }) => (disabled ? 'cursor: default;' : 'cursor: pointer;')};
  &:hover {
    background-color: ${Colors.PURPLE_20};
  }
`;

const Label = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? Colors.GRAY_60 : Colors.GRAY_100)};
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: ${FontFamily.ROBOTO};
`;

const StyledCard = styled(Card)`
  position: absolute;
  z-index: 1000;
  inset-block-start: 56px;
  cursor: pointer;
  width: 100%;
  padding: unset;
`;

const CardContent = styled.div`
  padding: 8px 0;
`;
