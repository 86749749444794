import { Colors } from '../theme/Colors';
import { FontFamily } from '../theme/FontFamily';

const ErrorDivStyle = `
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.ERROR};
  margin-top: 8px;
`;
export default ErrorDivStyle;
