import styled from 'styled-components';
import BackIcon from '../icons/BackIcon';

const StyledBackIcon = styled(BackIcon)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

export default StyledBackIcon;
