import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClickHandler from '../../../hooks/useOutsideClickHandler';
import { Colors } from '../../../theme/Colors';
import { Theme } from '../../../types';
import logger from '../../../utils/logger';
import { escapeRegExp } from '../../../utils/utils';
import TextInputWithLabelForwardRef from '../../TextInputWithLabelForwardRef';
import Card from '../../cards/Card';
import FilterSeachResults from './FilterSeachResults';

interface AutoCompleteSelectFilterProps {
  label: string;
  potentialFilters: Theme[];
  activeFilters: Theme[];
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  position: DOMRect;
  toggleFilter: (filter: Theme) => void;
  onInputKeyDown: (inputValue: string) => void;
  close: () => void;
}

const AutoCompleteSelectFilter: React.FC<AutoCompleteSelectFilterProps> = ({
  label,
  potentialFilters,
  activeFilters,
  placeholder,
  className,
  disabled,
  position,
  toggleFilter,
  onInputKeyDown,
  close,
}) => {
  const [, setActive] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(potentialFilters);

  useEffect(() => {
    setFilteredOptions(potentialFilters);
  }, [potentialFilters]);

  const [highlightRowIndex, setHighlightRowIndex] = useState<
    undefined | number
  >();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [inputRef.current]);

  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([containerRef], () => {
    close();
  });

  const onBlur = () => setTimeout(() => setActive(false), 300);

  useEffect(() => {
    if (!inputValue) {
      setFilteredOptions(potentialFilters);
    } else {
      const filtered = potentialFilters.filter(({ name }) =>
        inputValue ? new RegExp(escapeRegExp(inputValue), 'i').test(name) : true
      );

      setFilteredOptions(filtered);
    }
  }, [inputValue, potentialFilters]);

  const onInputChange = (val: string) => {
    setHighlightRowIndex(undefined);
    setInputValue(val);
  };

  const testEqual = (value: string, testAgainst: string) => {
    return value.toLowerCase() === testAgainst.toLowerCase();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (highlightRowIndex != undefined) {
        // selecting the highlighted theme
        const themeEqualToInput = filteredOptions[highlightRowIndex];
        toggleFilter(themeEqualToInput);
      } else {
        // using the input text
        const themeEqualToInput = potentialFilters.filter((theme) =>
          testEqual(inputValue, theme.name)
        )?.[0];
        if (themeEqualToInput) {
          logger.info('handleKeyDown: calling onSelectTheme');
          toggleFilter(themeEqualToInput);
        } else {
          logger.info('handleKeyDown: calling onInputKeyDown');
          onInputKeyDown(inputValue);
        }
      }
    } else if (event.keyCode === 38) {
      setHighlightRowIndex((prevState) =>
        prevState
          ? (prevState - 1) % filteredOptions.length
          : potentialFilters.length - 1
      );
    } else if (event.keyCode === 40) {
      setHighlightRowIndex((prevState) =>
        prevState != undefined ? (prevState + 1) % filteredOptions.length : 0
      );
    }
  };

  return (
    <StyledContainer
      ref={containerRef}
      className={className}
      style={{
        top: position.y + 40,
        left: position.x - 380,
        position: 'absolute',
      }}
    >
      <TextInputWithLabelForwardRef
        ref={inputRef}
        className={className}
        label={label}
        value={inputValue}
        onChange={(e) => onInputChange(e.target.value)}
        onFocus={() => setActive(true)}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={handleKeyDown}
      />
      <FilterSeachResults
        activeFilters={activeFilters}
        themes={filteredOptions}
        inputValue={inputValue}
        onToggleTheme={toggleFilter}
        highlightedRowIndex={highlightRowIndex}
      />
    </StyledContainer>
  );
};

export default AutoCompleteSelectFilter;

const StyledContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.WHITE};
  width: 400px;
  padding: 16px;
  box-shadow: 0px 8px 29px rgba(45, 45, 45, 0.7);
`;
