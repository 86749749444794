import styled from 'styled-components';
import { Colors } from '../../theme/Colors';

const TransparentButton = styled.button<{ withBorder?: boolean }>`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:focus,
  focus-visible {
    outline: none;
  }

  :active {
    ${({ withBorder }) =>
      withBorder ? `background-color: ${Colors.PRIMARY};` : ''};
  }
  :hover {
    ${({ withBorder }) =>
      withBorder ? `background-color: ${Colors.PRIMARY};` : ''};
  }

  :hover span {
    ${({ withBorder }) => (withBorder ? `color: ${Colors.BACKGROUND};` : '')};
  }
`;

export default TransparentButton;
