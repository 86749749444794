import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import TextInputWithLabelForwardRef from '../TextInputWithLabelForwardRef';
import PrimaryButton from '../buttons/PrimaryButton';
import Modal from '../modal/Modal';
import P2 from '../newTextComponents/P2';

interface AutoFocusedInputModalProps {
  show: boolean;
  onClose: () => void;
  value: string;
  label: string;
  placeholder: string;
  buttonLabel: string;
  onChangeInputValue: (value: string) => void;
  onClickCTA: () => void;
  maxLength?: number;
}

const AutoFocusedInputModal: React.FC<AutoFocusedInputModalProps> = ({
  show,
  onClose,
  value,
  label,
  placeholder,
  buttonLabel,
  onChangeInputValue,
  onClickCTA,
  maxLength,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [inputRef]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickCTA();
    }
  };

  return (
    <Modal hidden={!show} onClose={onClose}>
      <Container>
        <Input
          ref={inputRef}
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChangeInputValue(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          maxLength={maxLength}
        />
        {maxLength && (
          <MaxLengthLabel>
            {value ? `${value?.length}/${maxLength}` : `0/${maxLength}`}
          </MaxLengthLabel>
        )}
        <OKButton label={buttonLabel} onClick={onClickCTA} />
      </Container>
    </Modal>
  );
};

export default AutoFocusedInputModal;

const Container = styled.div``;

const OKButton = styled(PrimaryButton)`
  margin-top: 32px;
`;

const Input = styled(TextInputWithLabelForwardRef)`
  margin-top: 16px;
`;

const MaxLengthLabel = styled(P2)`
  color: ${Colors.P30};
  display: flex;
  justify-content: end;
  margin-top: 8px;
`;
