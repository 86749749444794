import * as Sentry from '@sentry/react';
export const initSentry = () => {
  Sentry.init({
    enabled: import.meta.env.VITE_ENVIRONMENT !== 'development',
    environment: import.meta.env.VITE_ENVIRONMENT,
    dsn: 'https://4bbad0297a00517b56c039895ac1172a@o4506628027908096.ingest.sentry.io/4506628033282048',
    integrations: [
      // new Sentry.BrowserTracing({
      //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: [
      //     'localhost:5173',
      //     /^https:\/\/blix\.io\/api/,
      //   ],
      // }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
