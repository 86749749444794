import { PropsWithChildren } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import CloudIcon from '../../assets/cloud.svg';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import Title from '../Title';
import PrimaryButton from '../buttons/PrimaryButton';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import Modal from '../modal/Modal';

interface LoadThemesModalProps {
  onClose: VoidFunction;
  hidden: boolean;
  onSelectFile: (acceptedFiles: File[]) => void;
}

const LoadThemesModal: React.FC<PropsWithChildren<LoadThemesModalProps>> = ({
  onClose,
  hidden,
  onSelectFile,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onSelectFile,
  });

  return (
    <StyledModal onClose={onClose} hidden={hidden}>
      <OuterContainer {...getRootProps()}>
        <Container>
          <Title>Select File</Title>

          <Container>
            <input {...getInputProps()} />
            <UploadImage src={CloudIcon} alt='cloud' />
            {isDragActive ? (
              <Text>Drop the file here ...</Text>
            ) : (
              <Text>Drag here, or click to select files</Text>
            )}
          </Container>
        </Container>
        <PrimaryButton type='button' label='Close' onClick={onClose} />
      </OuterContainer>
    </StyledModal>
  );
};

export default LoadThemesModal;

const StyledModal = styled(Modal)`
  width: 300px;
  padding: 0;
  border-radius: none;
`;

const OuterContainer = styled(CenteredVerticalFlex)`
  cursor: pointer;
  align-self: stretch;
  padding: 32px;
  border-radius: 12px;
`;

const Container = styled(CenteredVerticalFlex)``;

const Text = styled.p`
  font-family: ${FontFamily.ROBOTO};
  font-size: 16px;
  line-height: 15px;
  color: ${Colors.PRIMARY};
  margin-top: 24px;
`;

const UploadImage = styled.img`
  width: 70px;
`;
