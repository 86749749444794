import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';

interface TooltipBoundingRectProps {
  toolTipText: string;
  disabled?: boolean;
  shiftLeft?: boolean;
  shiftRight?: number;
  scrollOffset?: number;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
}

const TooltipBoundingRect: React.FC<
  PropsWithChildren<TooltipBoundingRectProps>
> = ({
  toolTipText,
  disabled,
  shiftLeft,
  shiftRight,
  scrollOffset = 0,
  style,
  children,
  containerStyle,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipDimensions, setTooltipDimensions] = useState({
    width: 0,
    height: 0,
  });

  const elementRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipDimensions({
        width: tooltipRef.current.offsetWidth,
        height: tooltipRef.current.offsetHeight,
      });
    }
  }, [showTooltip]);

  const show = () => {
    if (disabled) return;
    setShowTooltip(true);
  };

  const hide = () => {
    setShowTooltip(false);
  };

  const containerBoundingRect = elementRef.current?.getBoundingClientRect() ?? {
    top: 0,
    x: 0,
    width: 0,
  };

  return (
    <Container
      ref={elementRef}
      onMouseEnter={show}
      onMouseLeave={hide}
      style={containerStyle}
    >
      {children}
      {elementRef.current && (
        <ToolTipElement
          ref={tooltipRef}
          $show={showTooltip}
          $shiftLeft={shiftLeft}
          style={{
            position: 'fixed',
            top:
              containerBoundingRect.top -
              tooltipDimensions.height -
              scrollOffset -
              14,
            left:
              containerBoundingRect.x +
              containerBoundingRect.width / 2 -
              tooltipDimensions.width / 2 -
              (shiftLeft ? 85 : -4) +
              (shiftRight || 0),
            // whiteSpace: 'nowrap',
            zIndex: 1000,
            ...style,
          }}
        >
          <Text>{toolTipText}</Text>
        </ToolTipElement>
      )}
    </Container>
  );
};

export default TooltipBoundingRect;

const Container = styled.div`
  position: relative;
`;

const Text = styled.p`
  white-space: pre-wrap;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.B100};
`;

const ToolTipElement = styled.div<{ $show?: boolean; $shiftLeft?: boolean }>`
  max-width: 280px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px #0000001a;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s, visibility 0.4s;
  ${({ $show }) => ($show ? 'opacity: 1; visibility: visible;' : null)}

  &:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid white 10px;
    bottom: -10px;
    content: ' ';
    height: 0;
    left: ${({ $shiftLeft }) => ($shiftLeft ? '82.5%' : '50%')};
    margin-left: -13px;
    position: absolute;
    width: 0;
  }
`;
