import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { fetchHealthStatus } from './api/pipelineApi';
import { FormProvider } from './state/FormContext';
import logger from './utils/logger';

const NewFlow: React.FC = () => {
  const { error } = useQuery({
    queryKey: ['healthStatus'],
    queryFn: fetchHealthStatus,
    refetchInterval: 300000, // Refetch every 5 minutes
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (error) {
    logger.info('health check error: ', error);
  }
  return (
    <FormProvider>
      <Container>
        <Outlet />
      </Container>
    </FormProvider>
  );
};

export default NewFlow;

const Container = styled.div`
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-block-end: 0px;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
`;
