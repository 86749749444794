import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { JobDisplayData } from '../../types';
import { isColumnDefinitionEditDisabled } from '../../utils/utils';
import TextInputWithTooltip from '../TextInputWithTooltip';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import P2 from '../newTextComponents/P2';
import PreviewWithTooltip from '../preview/PreviewWithTooltip';
import JobAction from './JobAction';

interface QuestionRowProps {
  projectId: string;
  job: JobDisplayData;
  onChangeJob: (job: JobDisplayData) => void;
  onClickJobAction: (job: JobDisplayData) => void;
  onReAnalyzeQuestion: (job: JobDisplayData) => void;
}

const QuestionRow: React.FC<QuestionRowProps> = ({
  projectId,
  job,
  onChangeJob,
  onClickJobAction,
  onReAnalyzeQuestion,
}) => {
  const isDisabled = isColumnDefinitionEditDisabled(job);

  return (
    <Container>
      <IDContainer>
        <TextInputWithTooltip
          value={job.questionId ?? ''}
          onChange={(e) => {
            const temp = { ...job };
            temp.questionId = e.target.value;
            onChangeJob(temp);
          }}
          isDisabled={isDisabled}
        />
      </IDContainer>
      <ColumnTitleContainer>
        <PreviewWithTooltip
          text={job.userDefinedTitle ?? job.columnHeader}
          previewTextStyle={{ color: Colors.B100 }}
        />
      </ColumnTitleContainer>
      <PreviewContainer>
        <PreviewWithTooltip text={job.preview} />
      </PreviewContainer>
      <RowsCounterContainer>
        <RowsCount>
          {job.rowCount ? job.rowCount : 'No data to analyze'}
        </RowsCount>
      </RowsCounterContainer>
      <ActionContainer>
        <JobAction
          projectId={projectId}
          job={job}
          onClick={() => onClickJobAction(job)}
          onReAnalyzeQuestion={() => onReAnalyzeQuestion(job)}
        />
      </ActionContainer>
    </Container>
  );
};

export default QuestionRow;

const Container = styled(CenteredHorizontalFlex)`
  &:hover {
    background-color: ${Colors.B10};
  }

  height: 74px;
  justify-content: space-between;
  position: relative;
  &:hover > .cm-menu-icon-visible {
    // Targeting the child component on hover
    opacity: 1;
    visibility: visible;
  }
  &:hover .cm-edit-icon-visible {
    // Targeting an decendent child component on hover
    opacity: 1;
    visibility: visible;
  }
  &:hover .cm-menu-icon-visible {
    // Targeting an decendent child component on hover
    opacity: 1;
    visibility: visible;
  }
`;

const RowsCount = styled(P2)`
  color: ${Colors.B80};
`;

const IDContainer = styled.div`
  flex: 1;
`;

const ColumnTitleContainer = styled.div`
  flex: 4;
`;

const PreviewContainer = styled.div`
  flex: 4;
  overflow: hidden;
`;

const RowsCounterContainer = styled.div`
  flex: 2;
`;

const ActionContainer = styled.div`
  flex: 2;
`;
