import React from 'react';

interface ChevronRightIconProps {
  className?: string;
  fill?: string;
}

const ChevronRightIcon: React.FC<ChevronRightIconProps> = ({
  className,
  fill = '#666666',
}) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2004 8.23966C10.4823 7.93613 10.9568 7.91856 11.2603 8.20041L14.7603 11.4504C14.9132 11.5923 15 11.7915 15 12C15 12.2086 14.9132 12.4077 14.7603 12.5496L11.2603 15.7996C10.9568 16.0815 10.4823 16.0639 10.2004 15.7603C9.91856 15.4568 9.93613 14.9823 10.2397 14.7004L13.1478 12L10.2397 9.2996C9.93613 9.01775 9.91856 8.5432 10.2004 8.23966Z'
        fill={fill}
      />
    </svg>
  );
};

export default ChevronRightIcon;
