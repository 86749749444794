import React from 'react';

interface TrashIconProps {
  className?: string;
}

const TrashIcon: React.FC<TrashIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke='#DDDDDD' />
      <path
        d='M16.6659 11.3335V17.6002C16.6659 17.8211 16.4868 18.0002 16.2659 18.0002H7.73252C7.51161 18.0002 7.33252 17.8211 7.33252 17.6002V11.3335'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6665 15.3335V11.3335'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3325 15.3335V11.3335'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.9995 8.66667H14.6662M14.6662 8.66667V6.4C14.6662 6.17909 14.4871 6 14.2662 6H9.73285C9.51193 6 9.33285 6.17909 9.33285 6.4V8.66667M14.6662 8.66667H9.33285M5.99951 8.66667H9.33285'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.6659 11.3335V17.6002C16.6659 17.8211 16.4868 18.0002 16.2659 18.0002H7.73252C7.51161 18.0002 7.33252 17.8211 7.33252 17.6002V11.3335'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6665 15.3335V11.3335'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3325 15.3335V11.3335'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.9995 8.66667H14.6662M14.6662 8.66667V6.4C14.6662 6.17909 14.4871 6 14.2662 6H9.73285C9.51193 6 9.33285 6.17909 9.33285 6.4V8.66667M14.6662 8.66667H9.33285M5.99951 8.66667H9.33285'
        stroke='#666666'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TrashIcon;
