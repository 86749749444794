import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';

const H2 = styled.span`
  font-family: ${FontFamily.ROBOTO};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  color: ${Colors.B100};
`;

export default H2;
