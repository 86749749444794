import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

interface FormContextType {
  isDemo: boolean;
  setIsDemo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormContext = createContext<FormContextType>({
  isDemo: false,
  setIsDemo: () => {},
});

export const FormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isDemo, setIsDemo] = useState(false);

  return (
    <FormContext.Provider
      value={{
        isDemo,
        setIsDemo,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
