import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Title from '../Title';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import CircleOverSquareLoader from '../loaders/CircleOverSquareLoader';
import Modal from '../modal/Modal';

interface LoadingModalProps {
  title?: string;
  hidden: boolean;
}

const LoadingModal: React.FC<PropsWithChildren<LoadingModalProps>> = ({
  hidden,
  title = 'Loading...',
}) => {
  return (
    <StyledModal onClose={() => {}} hidden={hidden}>
      <Container>
        <StyledTitle>{title}</StyledTitle>
        <CircleOverSquareLoader size='small' />
      </Container>
    </StyledModal>
  );
};

export default LoadingModal;

const StyledModal = styled(Modal)`
  width: 200px;
  max-height: 80%;
`;

const Container = styled(CenteredVerticalFlex)``;
const StyledTitle = styled(Title)`
  margin-bottom: 32px;
`;
