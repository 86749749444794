import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import ButtonWithIcon from './ButtonWithIcon';

const FilterButtonWithActive = styled(ButtonWithIcon)<{ disabled?: boolean }>`
  padding: 0;
  > svg {
    border: 1px solid ${Colors.B20};
  }
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  > svg path {
    ${({ disabled }) =>
      disabled ? `fill: ${Colors.B20}` : `fill: ${Colors.P100}`};
  }

  > svg:hover {
    background-color: ${Colors.P10};
  }
  &:active > svg {
    ${({ disabled }) => (disabled ? `` : `background-color: ${Colors.P100};`)};
  }

  &:active > svg path {
    ${({ disabled }) =>
      disabled ? `fill: ${Colors.B20}` : `fill: ${Colors.WHITE}`};
  }
  &:active > svg rect {
    ${({ disabled }) => (disabled ? `` : `fill: transparent`)};
  }
`;

export default FilterButtonWithActive;
