import { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import Label from '../Label';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import SelectInput from '../form/select/SelectInput';

interface CohortsAggregateGraphFilterProps {
  onSelectFilter: (value: string) => void;
  filterValues?: string[];
  isEnabled?: boolean;
}

const CohortsAggregateGraphFilter: React.FC<
  CohortsAggregateGraphFilterProps
> = ({ onSelectFilter, filterValues, isEnabled = true }) => {
  const [filterType, setFilterType] = useState<string>('owner');
  const [filterByValue, setFilterByValue] = useState<string>('');

  const handleSelectFilterType = (event: any) => {
    setFilterType(event.target.value);
  };

  const handleSelectFilterValue = (value: string) => {
    setFilterByValue(value);
    onSelectFilter(value);
  };

  return (
    <Container>
      <StyledLabel>Filter by owner:</StyledLabel>
      <select
        style={{ display: 'none' }}
        value={filterType}
        onChange={handleSelectFilterType}
        disabled={!isEnabled}
      >
        <option disabled value={''}>
          Please select metadata to filter by
        </option>
        {/* {dashboardData.column_definitions.map(
          (metadataType: any, index: number) => (
            <option key={index} value={metadataType.title}>
              {metadataType.title}
            </option>
          )
        )} */}
        {filterValues?.map((filterValue: string, index: number) => (
          <option key={index} value={filterValue}>
            {filterValue}
          </option>
        ))}
      </select>
      {filterValues ? (
        <Select
          label=''
          value={filterByValue}
          onChange={handleSelectFilterValue}
          options={[
            {
              label: 'All',
              value: '',
            },
          ].concat(
            filterValues.map((filter: string) => ({
              label: filter,
              value: filter,
            }))
          )}
          cardStyle={{ maxHeight: 400, overflowY: 'scroll' }}
        />
      ) : null}
    </Container>
  );
};

export default CohortsAggregateGraphFilter;

const Container = styled(CenteredHorizontalFlex)`
  margin-bottom: 16px;
  margin-left: 50px;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  font-size: 16px;
  /* color: ${Colors.PRIMARY}; */
`;

const Select = styled(SelectInput)`
  width: 90px;
`;
