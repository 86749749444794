import React from 'react';
import { useEffect } from 'react';

const useOutsideClickHandler = (
  refs: React.RefObject<any>[],
  onClickOutside: VoidFunction
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      const containingRefs = refs.filter(
        (ref) => !ref.current || ref.current.contains(event.target)
      );
      if (containingRefs.length === 0) {
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, refs);
};

export default useOutsideClickHandler;
