import React from 'react';
import styled from 'styled-components';
import { ButtonOnClickHandler } from '../../../types';
import Label from '../../Label';
import Button from '../../buttons/Button';
import {
  buttonStyle,
  containerStyle,
  placeholderTextStyle,
} from './style-elements';

export interface ButtonInputWithLabelProps {
  label: string;
  buttonText: string;
  onClick: ButtonOnClickHandler;
  placeholder?: string;
  disabled?: boolean;
}

const ButtonInputWithLabel: React.FC<ButtonInputWithLabelProps> = ({
  label,
  buttonText,
  onClick,
  placeholder,
  disabled = false,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <StyledInputButton disabled={!!disabled} type='button' onClick={onClick}>
        {buttonText ? (
          buttonText
        ) : (
          <PlaceholderText>{placeholder}</PlaceholderText>
        )}
      </StyledInputButton>
    </Container>
  );
};

export default ButtonInputWithLabel;

const Container = styled.div`
  ${containerStyle}
`;

const StyledInputButton = styled(Button)<{ disabled?: boolean }>`
  ${buttonStyle}
  ${({ disabled }) => (!!disabled ? 'cursor: default' : '')};
  font-weight: normal;
`;

const PlaceholderText = styled(Label)`
  ${placeholderTextStyle}
`;
