import React, { useRef } from 'react';
import styled from 'styled-components';
import CursorIcon from '../../assets/cursor.png';
import ThreeDotsMenuIcon from '../../assets/three_dots_menu.svg';
import TrashIcon from '../../assets/trash.svg';
import useOutsideClickHandler from '../../hooks/useOutsideClickHandler';
import { Colors } from '../../theme/Colors';
import VerticalFlex from '../containers/VerticalFlex';
import MenuItemTextButtonWithIcon from '../menu/MenuItemTextButtonWithIcon';

interface ProjectItemMenuProps {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  onRenameProject: () => void;
  onRemoveProject: () => void;
}

const ProjectItemMenu: React.FC<ProjectItemMenuProps> = ({
  showMenu,
  setShowMenu,
  onRenameProject,
  onRemoveProject,
}) => {
  const menuIconRef = useRef<HTMLImageElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([menuIconRef, menuRef], () => {
    setShowMenu(false);
  });

  return (
    <>
      <MenuIcon
        ref={menuIconRef}
        className='menu-icon-visible'
        src={ThreeDotsMenuIcon}
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu((x) => !x);
        }}
        $visible={showMenu}
      />
      <Menu
        ref={menuRef}
        $visible={showMenu}
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu((x) => !x);
        }}
      >
        <MenuContents>
          <MenuButton
            text='Rename Project'
            icon={<MenuItemIcon src={CursorIcon} />}
            onClick={onRenameProject}
            textStyle={{ fontSize: 14 }}
          />
          <MenuButton
            text='Move to Trash'
            icon={<MenuItemIcon src={TrashIcon} />}
            onClick={onRemoveProject}
            textStyle={{ fontSize: 14 }}
          />
        </MenuContents>
      </Menu>
    </>
  );
};

export default ProjectItemMenu;

const MenuContents = styled(VerticalFlex)`
  position: absolute;
  right: 52px;
  top: 26px;
  z-index: 1;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
`;

const MenuButton = styled(MenuItemTextButtonWithIcon)``;

const MenuIcon = styled.img<{ $visible: boolean }>`
  position: absolute;
  right: 6px;
  top: 14px
  height: 30px;
  width: 30px;
  opacity: 0;
  z-index: 1;
  padding: 6px;
  border-radius: 6px;
  visibility: hidden;
  transition: opacity 0.7s, visibility 0.7s;

  ${({ $visible }) => ($visible ? 'opacity: 1; visibility: visible;' : null)}

  &:hover {
    ${({ $visible }) =>
      !$visible &&
      `
    background-color: ${Colors.GRAY_20};
    border-radius: 50%;
  `}
  }

  ${({ $visible }) =>
    $visible &&
    `
    background-color: ${Colors.GRAY_40};
    border-radius: 50%;
  `}
`;

const Menu = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  ${({ $visible }) => ($visible ? 'opacity: 1; visibility: visible;' : null)}
`;

const MenuItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;
