import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { PropsWithChildren } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options: {} = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return `${context.dataset.label}: ${context.raw['data.percent']}% (${context.raw['data.raw']})`;
        },
      },
    },
  },
  parsing: {
    xAxisKey: 'data\\.percent',
    yAxisKey: 'data\\.percent',
  },
  scales: {
    x: {
      ticks: {
        min: 0,
        max: 100,
        callback: function (value: number) {
          return value + '%';
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

interface HorizontalGraphProps {
  histogram: { theme: string; count: number }[];
  respondentsCount: number;
}

const HorizontalGraph: React.FC<PropsWithChildren<HorizontalGraphProps>> = ({
  histogram,
  respondentsCount,
}) => {
  const cleanedThemes = histogram.filter((item) => item.theme !== 'N/A');
  let sortedThemes = cleanedThemes.sort((a, b) => b.count - a.count);
  sortedThemes = sortedThemes.slice(0, 10);
  const labels = sortedThemes.map((item) => item.theme);
  const dataset = sortedThemes.map((item) => item.count);
  const dataWithPercent = dataset.map((x) => ({
    'data.percent': Math.floor((100 * x) / respondentsCount),
    'data.raw': x,
  }));
  const data = {
    labels,
    datasets: [
      {
        label: 'Reviews',
        data: dataWithPercent,
        backgroundColor: '#3394d1',
      },
    ],
  };
  return (
    <Container>
      <Bar options={options} data={data} />
    </Container>
  );
};

export default HorizontalGraph;

const Container = styled.div`
  width: 75%;
  margin: auto;
  padding-bottom: 48px;
`;
