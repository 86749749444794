import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Theme } from '../../types';
import { debounce } from '../../utils/tools';
import AddThemeItem from './AddThemeItem';
import OtherThemeItem from './OtherThemeItem';
import ThemeItem from './ThemeItem';

interface ThemesListProps {
  themes: Theme[];
  onRemoveTheme: (index: number, theme: Theme) => void;
  onUpdateTheme: (index: number, theme: Theme) => void;
  onAddTheme: VoidFunction;
  tagSampleCounter: { [themeName: string]: number };
  resultsCount?: number;
}

const ThemesList: React.FC<ThemesListProps> = ({
  themes,
  onRemoveTheme,
  onUpdateTheme,
  onAddTheme,
  tagSampleCounter,
  resultsCount,
}) => {
  const nameInputRefs = useRef<Array<HTMLInputElement | null>>([]);

  // Function to focus on the last added NameInput
  const focusLastAddedNameInput = () => {
    if (nameInputRefs.current.length > 0) {
      const lastInput = nameInputRefs.current[nameInputRefs.current.length - 1];
      // debugger
      if (lastInput) {
        lastInput.focus();
      }
    }
  };

  const prevLengthRef = useRef(themes.length);

  useEffect(() => {
    if (themes.length > prevLengthRef.current) {
      focusLastAddedNameInput();
    }
    prevLengthRef.current = themes.length;
    nameInputRefs.current = nameInputRefs.current.filter((ref) => ref !== null);
  }, [themes]);

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!gridContainerRef.current) return;

    const handleScroll = debounce(() => {
      setScrollPosition({
        x: gridContainerRef.current?.scrollLeft ?? 0,
        y: gridContainerRef.current?.scrollTop ?? 0,
      });
    }, 100); // Debounce time of 100 milliseconds

    gridContainerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      gridContainerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [gridContainerRef]);

  const [scrolledToTop, setScrolledToTop] = useState(false);
  useEffect(() => {
    if (
      scrolledToTop ||
      !gridContainerRef.current ||
      !themes ||
      themes.length === 0
    )
      return;

    setScrolledToTop(true);

    setTimeout(() => {
      gridContainerRef?.current?.scrollTo(0, 0);
    }, 1);
  }, [gridContainerRef, themes, scrolledToTop]);

  return (
    <Grid ref={gridContainerRef}>
      {themes.map((theme, i) => (
        <ThemeItem
          key={theme.id}
          theme={theme}
          onRemove={(v) => onRemoveTheme(i, v)}
          setValue={(v) => onUpdateTheme(i, v)}
          ref={(el) => (nameInputRefs.current[i] = el)}
          withPlaceholder={i === 0}
          tagSampleCounter={tagSampleCounter[theme.name]}
          totalTagsCount={resultsCount}
          scrollOffset={scrollPosition.y}
        />
      ))}
      <AddThemeItem onClick={onAddTheme} />
      <OtherThemeItem
        tagSampleCounter={tagSampleCounter['Other']}
        totalTagsCount={resultsCount}
        scrollOffset={scrollPosition.y}
      />
    </Grid>
  );
};

export default ThemesList;

const Grid = styled.div`
  width: 899px;
  max-height: calc(100vh - 272px);
  overflow-y: scroll;
  justify-content: center;
  grid-template-columns: repeat(2, 430px);
  margin-top: 22px;
  display: grid;
  column-gap: 24px;
  row-gap: 16px;
`;
