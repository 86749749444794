import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import CircleOverSquareLoader from '../loaders/CircleOverSquareLoader';
import Label from '../newTextComponents/Label';

interface TagResultsLoaderProps {
  isFirstLoad?: boolean;
  isFetchingNextPage?: boolean;
}

const TagResultsLoader: React.FC<PropsWithChildren<TagResultsLoaderProps>> = ({
  isFirstLoad,
  isFetchingNextPage,
}) => {
  return (
    <Container $isFetchingNextPage={isFetchingNextPage}>
      <CircleOverSquareLoader />
      {isFetchingNextPage ? (
        <StyledLabel>
          Hang tight! We're coding the next batch of your data
        </StyledLabel>
      ) : isFirstLoad ? (
        <StyledLabel>Hang tight! We're coding your data</StyledLabel>
      ) : (
        <StyledLabel>
          Hang tight! We're applying the latest changes and coding your data
        </StyledLabel>
      )}
    </Container>
  );
};

export default TagResultsLoader;

const Container = styled(CenteredVerticalFlex)<{
  $isFetchingNextPage?: boolean;
}>`
  ${({ $isFetchingNextPage }) => $isFetchingNextPage && `margin-top: 30px;`}
  position: relative;
  height: calc(100vh - 288px);
  margin-bottom: 20px;
  padding: 6px;
  justify-content: center;
  gap: 16px;
`;

const StyledLabel = styled(Label)`
  width: 306px;
  text-align: center;
  opacity: 0.6;
`;
