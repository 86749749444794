import { User } from '../hooks/useUser';
import logger from './logger';

const USER_LOCAL_STORAGE_KEY = 'blix-user';

export function saveUser(user: User): void {
  logger.info(`saveUser - ${user}`);
  localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

export function getUser(): User | undefined {
  const user = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  return user ? JSON.parse(user) : undefined;
}

export function removeUser(): void {
  logger.info(`removeUser`);
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
}
