import * as Sentry from '@sentry/react';

export type LogFunction = (message: string, ...optionalParams: any[]) => void;

const ConsoleLog: LogFunction = (message, ...optionalParams) => {
  const debugAssistingPrefix = 'blix-app';
  console.log(`${debugAssistingPrefix}:`, message, ...optionalParams);
};

const ConsoleErrorLog: LogFunction = (message, ...optionalParams) => {
  const debugAssistingPrefix = 'blix-app';
  console.error(`${debugAssistingPrefix}:`, message, ...optionalParams);
};

const DummyLog: LogFunction = () => {};

const log =
  import.meta.env.VITE_ENVIRONMENT === 'production' ? DummyLog : ConsoleLog;

const SentryLog: LogFunction = (
  message: string,
  error: Error,
  metaData: {} = {}
) => {
  log(message, error);
  Sentry.captureException(error, { extra: { message, ...metaData } });
};

const errorLog = ['production', 'staging'].includes(
  import.meta.env.VITE_ENVIRONMENT
)
  ? SentryLog
  : ConsoleErrorLog;

const logger = {
  info: log,
  error: errorLog,
};

export default logger;
