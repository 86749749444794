import { SuperTokensWrapper } from 'supertokens-auth-react';
import { PasswordlessComponentsOverrideProvider } from 'supertokens-auth-react/recipe/passwordless';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { AuthRecipeComponentsOverrideContextProvider } from 'supertokens-auth-react/ui';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import * as reactRouterDom from 'react-router-dom';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';

import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import NewFlow from './NewFlow';
import RootPage from './RootPage';
import { initSuperTokens } from './auth/supertokens';
import AnalysisSetupPage from './components/AnalysisSetupPage';
import CreateProjectPage from './components/CreateProjectPage';
import TrendsChart from './components/dashboard/TrendsChart';

import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui';
import SecondFactor from './auth/SecondFactor';
import CodebookEditorPage from './pages/CodebookEditorPage';
import ProjectPage from './pages/ProjectPage';
import ProjectsPage from './pages/ProjectsPage';
import ReviewProjectPage from './pages/ReviewProjectPage';
import SplitViewCodebookEditorPage from './pages/SplitViewCodebookEditorPage';
import { initHotjar } from './utils/hotjar';
import { initSentry } from './utils/sentry';

initSentry();
initHotjar();
initSuperTokens();

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <SuperTokensWrapper>
      <AuthRecipeComponentsOverrideContextProvider
        components={{
          AuthPageHeader_Override: ({ DefaultComponent, ...props }) => {
            if (props.factorIds.includes('otp-email')) {
              <div
                style={{
                  fontSize: '30px',
                  marginBottom: '10px',
                }}
              >
                Second factor auth
              </div>;
            }
            return <DefaultComponent {...props} />;
          },
        }}
      >
        <PasswordlessComponentsOverrideProvider
          components={{
            // we override the component which shows the change email button
            PasswordlessUserInputCodeFormFooter_Override: ({
              DefaultComponent,
              ...props
            }) => {
              const session = useSessionContext();

              if (
                session.loading !== true &&
                session.accessTokenPayload.email === undefined
              ) {
                // this will show the change email button
                return <DefaultComponent {...props} />;
              }

              // this will hide the change email button
              return null;
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Router>
              <Routes>
                {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
                  ThirdPartyPreBuiltUI,
                  EmailPasswordPreBuiltUI,
                  EmailVerificationPreBuiltUI,
                  // PasswordlessPreBuiltUI,
                ])}
                <Route
                  path='/second-factor'
                  element={
                    <SessionAuth key='/second-factor'>
                      <SecondFactor />
                    </SessionAuth>
                  }
                />
                <Route path='/' element={<RootPage />} />
                <Route
                  path='/projects'
                  element={
                    <SessionAuth>
                      <NewFlow />
                    </SessionAuth>
                  }
                >
                  <Route index element={<ProjectsPage />} />
                  <Route path='trends/:orgId' element={<TrendsChart />} />
                  <Route path=':projectId' element={<ProjectPage />} />
                  <Route
                    path=':projectId/dashboard/:jobId/:jobVersionId'
                    element={<ReviewProjectPage />}
                  />
                  <Route
                    path=':projectId/:jobId/:jobVersionId/setup'
                    element={<AnalysisSetupPage />}
                  />
                  <Route
                    path=':projectId/create-project'
                    element={<CreateProjectPage />}
                  />
                  <Route
                    path=':projectId/:jobId/:jobVersionId/codebook-editor'
                    element={<CodebookEditorPage />}
                  />
                  <Route
                    path=':projectId/:jobId/:jobVersionId/split-view-codebook-editor'
                    element={<SplitViewCodebookEditorPage />}
                  />
                </Route>
                <Route path='*' element={<Navigate replace to='/projects' />} />
              </Routes>
            </Router>
          </QueryClientProvider>
        </PasswordlessComponentsOverrideProvider>
      </AuthRecipeComponentsOverrideContextProvider>
    </SuperTokensWrapper>
  );
};

export default App;
