import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../theme/Colors';
import ThemeRowHighlightedText from './ThemeRowHighlightedText';

interface ThemeRowWithCheckboxProps {
  label: string;
  selected: boolean;
  onClick: VoidFunction;
  highlightText?: string;
  highlightRow?: boolean;
  checked: boolean;
}

const ThemeRowWithCheckbox: React.FC<
  PropsWithChildren<ThemeRowWithCheckboxProps>
> = ({
  label,
  selected,
  onClick,
  highlightText,
  highlightRow = false,
  checked,
}) => {
  return (
    <Container onClick={onClick} $highlightRow={highlightRow}>
      <Checkbox
        type='checkbox'
        disabled={false}
        checked={checked}
        onChange={() => {}}
      />
      <ThemeRowHighlightedText
        selected={selected}
        text={label}
        highlightText={highlightText}
      />
    </Container>
  );
};

export default ThemeRowWithCheckbox;

const Container = styled.div<{ $highlightRow: boolean }>`
  background-color: ${({ $highlightRow }) =>
    $highlightRow ? Colors.CATEGORYֹֹֹ_SELECTED_BACKGROUND : 'white'};
  display: flex;
  gap: 12px;
  padding: 20px;
  cursor: pointer;
`;
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-color: ${Colors.B30};
  accent-color: ${Colors.P100};
`;
