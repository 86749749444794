import React from 'react';
import styled from 'styled-components';
import Illustration from '../assets/illustrations/empty_projects_page_illustration.svg';
import BlixLogoIcon from '../assets/logo.svg';
import { Colors } from '../theme/Colors';
import PrimaryButton from './buttons/PrimaryButton';
import CenteredVerticalFlex from './containers/CenteredVerticalFlex';
import PlusIcon from './icons/PlusIcon';
import H1 from './newTextComponents/H1';
import P1 from './newTextComponents/P1';

interface EmptyProjectsProps {
  onCreateProject: VoidFunction;
}

const EmptyProjects: React.FC<EmptyProjectsProps> = ({ onCreateProject }) => {
  return (
    <Container>
      {/* <Background src={Illustration} /> */}
      <Contents>
        <Logo src={BlixLogoIcon} alt='logo' />
        <Headers>
          <H1>Welcome to Blix!</H1>
          <SubTitle>Start coding your first project here</SubTitle>
        </Headers>
        <PrimaryButton
          label='New Project'
          onClick={onCreateProject}
          icon={<PlusIcon />}
        />
      </Contents>
    </Container>
  );
};

export default EmptyProjects;

const Container = styled(CenteredVerticalFlex)`
  justify-content: center;
  flex: 1;
  width: 100%;
  background-image: url(${Illustration}); /* Path to your SVG background image */
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  background-position: center; /* Centers the background image */
`;

const Contents = styled(CenteredVerticalFlex)`
  gap: 40px;
  margin-bottom: 126px;
`;

const Headers = styled(CenteredVerticalFlex)`
  gap: 12px;
`;

const Logo = styled.img`
  height: 80px;
  width: 80px;
`;

const SubTitle = styled(P1)`
  color: ${Colors.B50};
`;
